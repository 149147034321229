<template lang="pug">
v-dialog.mb-15(
  v-model="dialogLeagueDetail",
  :scrollable="true",
  max-width="100%",
  persistent
)
  v-card(v-if="leagueData != null")
    v-container.container--fluid.card_header(
      :class="{ gc: leagueData.is_official }"
    )
      v-card-title.flex-column.justify-center.pa-0
        .league_title.d-flex.align-center
          svg-icon.league_icon(
            v-if="leagueData.is_official",
            iconClass="gc_logo"
          )
          .league_name.ml-1 {{ leagueData.name }}
          font-awesome-icon.league_private.ml-2(
            v-if="!leagueData.is_private",
            :icon="['fas', 'globe-americas']"
          )
          font-awesome-icon.league_private.ml-2(
            v-if="leagueData.is_private",
            :icon="['fas', 'key']"
          )
    v-card-text.px-3.px-md-12.py-10
      v-container.px-0
        .league_status_wrapper.d-flex.align-center
          .league_status.ml-0.ml-md-4.d-inline-block
            font-awesome-icon.mr-2(:icon="['fas', 'info-circle']")
            span {{ getLeagueStatus }}
            span.ml-2(
              v-if="leagueData.is_owner || (!leagueData.is_owner && leagueData.state == 'NEW')"
            ) |

          .league_self_state.ml-2.d-inline-block(
          )
            span {{ leagueData.is_join ? '已加入' : '未加入' }}
          .league_master.ml-2.d-none.d-md-block(v-if="leagueData.is_owner")
            font-awesome-icon.mr-2(:icon="['fas', 'user']")
            span 你是聯賽管理員
          v-spacer
          .btns.d-none.d-md-block
            .btn_primary_blue.ml-2(
              @click="onJoinLeagueBtnClick",
              v-if="!leagueData.is_owner && !leagueData.is_join && leagueData.state == 'NEW' && leagueData.current_team_count < leagueData.max_team_count"
            ) 加入聯盟
            .btn_light_gray.ml-2(
              v-if="!leagueData.is_owner && !leagueData.is_join && leagueData.state == 'NEW' && leagueData.current_team_count >= leagueData.max_team_count"
            ) 已額滿
            .btn_red.ml-2(
              @click="onDeleteLeagueBtnClick",
              v-if="leagueData.is_owner && leagueData.state == 'NEW'"
            ) 刪除聯盟
            .btn_red.ml-2(
              @click="onQuitLeagueBtnClick",
              v-if="!leagueData.is_owner && leagueData.is_join && leagueData.state == 'NEW'"
            ) 退出聯盟
        .btns_mo.d-md-none.d-flex.align-center.mt-4
          .league_master.ml-2.d-inline-block(v-if="leagueData.is_owner")
            font-awesome-icon.mr-2(:icon="['fas', 'user']")
            span 你是聯賽管理員
          v-spacer
          .btn_primary_blue.ml-2(
            @click="onJoinLeagueBtnClick",
            v-if="!leagueData.is_owner && !leagueData.is_join && leagueData.state == 'NEW' && leagueData.current_team_count < leagueData.max_team_count"
          ) 加入聯盟
          .btn_light_gray.ml-2(
            v-if="!leagueData.is_owner && !leagueData.is_join && leagueData.state == 'NEW' && leagueData.current_team_count >= leagueData.max_team_count"
          ) 已額滿
          .btn_red.ml-2(
            @click="onDeleteLeagueBtnClick",
            v-if="leagueData.is_owner && leagueData.state == 'NEW'"
          ) 刪除聯盟
          .btn_red.ml-2(
            @click="onQuitLeagueBtnClick",
            v-if="!leagueData.is_owner && leagueData.is_join && leagueData.state == 'NEW'"
          ) 退出聯盟
      v-container.mt-4.pt-4.pb-15.px-3.px-md-10(style="background: #f4f7fb")
        v-row
          v-col(cols="12", md="6")
            item-with-icon(
              iconName="warehouse",
              title="房間號碼",
              :content="leagueData.id + ''"
            )
          v-col(
            cols="12",
            md="6"
          )
            item-with-icon(
              iconName="key",
              title="房間類型",
              :actionBtn="leagueData.is_owner",
              :actionBtnStatus="isPwdEditable"
              @itemWithIconSave="saveRoomType",
              @itemWithIconAction="editRoomType",
            )
              template(#content)
                .set_room_type(v-if="isPwdEditable")
                  v-radio-group(v-model="isPublic", row="")
                    v-radio(label="公開", value="public")
                    v-radio(label="私人", value="secret")

                  v-text-field(
                    v-if="isPublic == 'secret'",
                    v-model="pwd",
                    :rules="[rules.required, rules.pwdRegax]",
                    counter,
                    maxlength="12",
                    hint="請輸入12字以內的密碼(限英文、數字)"
                  )
                .room_type(v-else)
                div(v-if="leagueData.is_owner")
                  .text.mt-2(style="#6a747e") {{ isPublic == 'public' ? '公開' : `私人 (${leagueData.room_key})` }}
                div(v-else)
                  .text.mt-2(style="#6a747e") {{ isPublic == 'public' ? '公開' : '私人' }}

                
          v-col(cols="12", md="6")
            item-with-icon(
              iconName="dollar-sign",
              title="最高獎金",
              :content="leagueData.max_reward + ''"
            )
          v-col(cols="12", md="6")
            item-with-icon(
              iconName="calendar",
              title="開始日期",
              :content="leagueData.start_date"
            )
          v-col(cols="12", md="6")
            item-with-icon(
              iconName="donate",
              title="入場費",
              :content="leagueData.entry_fee + ' (20%手續費)'"
            )
          v-col(cols="12", md="6")
            item-with-icon(
              iconName="donate",
              title="結束日期",
              :content="leagueData.end_date"
            )
          v-col(cols="12", md="6")
            item-with-icon(iconName="user", title="主辦")
              template(#content)
                svg-icon.avator_icon(
                  v-if="leagueData.is_official",
                  iconClass="gc_avatar"
                )
                img.avator_icon(
                  v-if="leagueData.is_official && leagueData.creator",
                  :src="leagueData.creator.photo != '' ? leagueData.creator.photo : gcAvatar"
                )
                span.avator_text(v-if="leagueData.creator") {{ leagueData.creator.nickname }}
          v-col(cols="12", md="6")
            item-with-icon(
              iconName="flag",
              title="比賽場數",
              :content="String(leagueData.game_count)"
            )
          v-col(cols="12", md="6")
            item-with-icon(iconName="users", title="人數")
              template(#content)
                span {{ leagueData.current_team_count }}
                span /{{ leagueData.max_team_count }}人
        v-row
          v-col.player_list_title(cols="12", md="6")
            item-with-icon(iconName="headset", title="參賽玩家")
          //- v-spacer
          v-col.action.d-flex.align-center(cols="12", md="6")
            input.search(placeholder="搜尋玩家 (名稱/email)", v-model="searchText")
            .btn.btn_water_blue.edit_league_btn(
              v-if="leagueData.is_owner && leagueData.state == 'NEW'",
              @click="playersEditable = !playersEditable"
            ) {{ playersEditable ? '完成' : '編輯' }}
        v-row.flex-wrap.pt-3
          v-col.pr-0.flex-grow-0(
            v-for="(player, index) in leagueData.competitors",
            :key="index"
          )
            player-chip(
              :editable="playersEditable",
              :name="player.nickname",
              :email="player.email",
              :img="player.photo",
              :id="index",
              :showEdit="leagueData.is_owner && leagueData.state == 'NEW'",
              @onDeletePlayerBtnClick="onDeletePlayerBtnClick"
            )
    v-card-actions.justify-center
      v-btn(small, outlined, color="dark-1", text="", @click="closeDialog") close
  dialog-warning(
    :title="dialogWarningData.title",
    @closeWarningDialog="closeWarningDialog",
    @action="onActionBtnClick",
    :actionName="dialogWarningData.actionName",
    :dialogWarning="dialogWarningShow",
    :actionFlag="dialogWarningData.actionFlag"
  )
    template(#content)
      p {{ dialogWarningData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
  v-snackbar(
    v-model="snackbar",
    :timeout="timeout",
    :value="true",
    color="rgba(114,158,195,0.88)",
    elevation="0"
  )
    | {{ snackbarText }}
</template>

<script>
import {
  getSeasonData,
  checkMyTeam,
  joinLeague,
  quitLeague,
  cancelLeague,
  updateLeague,
  kickUserFromLeague,
} from "@/api/fantasy";
import { getUserInfo } from "@/api/user";

import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogError from "@/components/dialog/DialogError";
import DialogWarning from "@/components/dialog/DialogWarning";
import DialogLoading from "@/components/dialog/DialogLoading";
import ItemWithIcon from "@/components/fantasy/my_league/ItemWithIcon";
import PlayerChip from "@/components/fantasy/my_league/PlayerChip";
import gcAvatar from "@/assets/img/gc-avatar.png";

export default {
  name: "DialogLeagueDetail",
  props: {
    needUpdate: Boolean,
    dialogLeagueDetail: Boolean,
    leagueData: Object,
    room_key: String,
  },
  components: {
    DialogSuccess,
    DialogError,
    DialogWarning,
    DialogLoading,
    ItemWithIcon,
    PlayerChip,
  },
  data() {
    return {
      userInfo: null,
      seasonData: {},
      transactionTime: 0,
      leftValue: 0,

      pwd: "",
      isPwdEditable: false,
      isPublic: "public", //public, secret
      rules: {
        required: (value) => !!value || "欄位必填",
        // name: (v) => v.length <= 12 || "請輸入最多不超過12個字",
        pwdRegax: (v) => {
          const pattern = /^[a-zA-Z0-9]+$/;
          if (!pattern.test(v)) {
            return "請輸入數字或英文";
          }

          if (v.length > 12) {
            return "請輸入12字以內的密碼";
          }

          return true;
        },
      },
      snackbarText: "",
      snackbar: false,
      timeout: 2000,

      gcAvatar,
      position: "ALL",
      searchText: "",
      playersEditable: false,
      currentPlayerIndex: null,
      playerPage: 1,
      playerList: [],
      tempPlayerList: [],
      currentPlayerList: [],
      playerListSelect: [],
      playerIDListSelect: [],

      myTeamInfo: {},
      myTeamLocalPlayerPage: 1,
      myTeamPlayerList: [],
      myTeamLocalPlayerList: [],
      currentMyTeamLocalPlayerList: [],
      myTeamLocalPlayerSelect: [],
      myTeamLocalPlayerIDSelect: [],

      dialogWarningShow: false,
      dialogWarningData: {
        title: "",
        text: "",
        actionName: "",
        actionFlag: null,
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },
    };
  },
  watch: {
    needUpdate() {
      if (this.needUpdate) {
        this.getAchievement();
        this.$emit("setAchievementUpdated");
      }
    },
    isPublic() {
      if (this.isPublic == "public") {
        this.pwd = "";
      }
    },
    leagueData() {
      if (this.leagueData.is_private) {
        this.isPublic = "secret";
        this.pwd = this.leagueData.room_key;
      } else {
        this.isPublic = "public";
      }
    },
  },
  computed: {
    getLeagueStatus() {
      switch (this.leagueData.status) {
        case "uninitiated":
          return "建立中";
        case "inprogress":
          return "進行中";
        case "end":
          return "已結束";
        default:
          return "";
      }
    },
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      getUserInfo().then((response) => {
        this.userInfo = response.data;
        console.log("this.userInfo=", this.userInfo);
      });
    },
    getSeasonData() {
      let temp = {
        state: "IN_SEASON",
      };
      getSeasonData(temp).then((response) => {
        this.seasonData = response.data[0];
        this.checkMyTeam();
        console.log("this.seasonData=", this.seasonData);
      });
    },
    checkMyTeam() {
      if (this.$store.state.user.accessToken) {
        let temp = {
          season_id: this.seasonData.id,
        };
        checkMyTeam(temp).then((response) => {
          this.myTeamInfo = response.data.team;
          this.transactionTime =
            this.myTeamInfo.local_player_transaction_count_left / 2;
          this.leftValue = this.myTeamInfo.value_left;
        });
      }
    },
    updateLeague() {
      let data = {
        league_id: this.leagueData.id,
        room_key: this.pwd,
      };
      updateLeague(data).then(() => {
        this.$emit("reloadLeague", this.leagueData.id);
      });
    },
    onDeletePlayerBtnClick(index) {
      this.currentPlayerIndex = index;
      this.dialogWarningData.title = "警告";
      this.dialogWarningData.actionName = "踢除";
      this.dialogWarningData.actionFlag = 0;
      this.dialogWarningData.text =
        "確定要將玩家 " + this.leagueData.competitors[index].name + " 踢除嗎？";
      this.showWarningDialog();
    },
    onDeleteLeagueBtnClick() {
      this.dialogWarningData.title = "警告";
      this.dialogWarningData.actionName = "刪除";
      this.dialogWarningData.actionFlag = 1;
      this.dialogWarningData.text =
        "確定要將聯盟 " +
        this.leagueData.leagueName +
        " 刪除嗎？（GC 將收取 20% 手續費無法退回）";
      this.showWarningDialog();
    },
    onQuitLeagueBtnClick() {
      this.dialogWarningData.title = "警告";
      this.dialogWarningData.actionName = "退出";
      this.dialogWarningData.actionFlag = 2;
      this.dialogWarningData.text =
        "確定要退出聯盟 " +
        this.leagueData.leagueName +
        " 嗎？（GC 將收取 20% 手續費無法退回）";
      this.showWarningDialog();
    },
    onJoinLeagueBtnClick() {
      this.dialogWarningData.title = "提示";
      this.dialogWarningData.actionName = "加入";
      this.dialogWarningData.actionFlag = 3;
      this.dialogWarningData.text =
        "確定要加入聯盟 " +
        this.leagueData.name +
        " 嗎？（GC 將收取 20% 手續費）";
      this.showWarningDialog();

      if (this.userInfo.fantasy_coin < this.leagueData.entry_fee) {
        this.dialogWarningData.actionName = "錯誤";
        this.dialogWarningData.text = "餘額不足";
      }
    },
    onActionBtnClick() {
      switch (this.dialogWarningData.actionFlag) {
        case 0:
          this.deletePlayer();
          break;
        case 1:
          this.deleteLeague();
          break;
        case 2:
          this.quitLeague();
          break;
        case 3:
          this.joinLeague();
          break;
        case 4:
        default:
          this.closeWarningDialog();
          break;
      }
    },
    async deletePlayer() {
      await this.kickUserFromLeague();
      this.closeWarningDialog();
    },
    deleteLeague() {
      //deleteLeague
      let temp = {
        league_id: this.leagueData.id,
      };
      cancelLeague(temp).then(() => {
        this.$emit("reloadLeagueByCancel");
        this.closeWarningDialog();
      });
      this.closeWarningDialog();
    },
    quitLeague() {
      //quitLeague
      let temp = {
        league_id: this.leagueData.id,
      };
      quitLeague(temp).then(() => {
        this.$emit("reloadLeague", this.leagueData.id);
        this.closeWarningDialog();
      });
    },
    joinLeague() {
      //joinLeague
      let temp = {
        league_id: this.leagueData.id,
        room_key: this.room_key,
      };
      joinLeague(temp).then(() => {
        this.$emit("reloadLeague", this.leagueData.id);
        this.closeWarningDialog();
      });
    },
    async kickUserFromLeague() {
      let temp = {
        league_id: this.leagueData.id,
        magic_token:
          this.leagueData.competitors[this.currentPlayerIndex].magic_token,
      };
      await kickUserFromLeague(temp).then(() => {
        this.leagueData.competitors.splice(this.currentPlayerIndex, 1);
        this.$emit("reloadLeague", this.leagueData.id);
        this.closeWarningDialog();
      });
    },
    editRoomType() {
      if (this.leagueData.is_private) {
        this.isPublic = "secret";
        this.pwd = this.leagueData.room_key;
      } else {
        this.isPublic = "public";
      }
      this.isPwdEditable = !this.isPwdEditable;
    },
    saveRoomType() {
      let roomType = this.isPublic == "public" ? "公開" : "私人";

      //regax
      if (this.isPublic == "secret") {
        const pattern = /^[a-zA-Z0-9]+$/;
        if (!pattern.test(this.pwd) || this.pwd.length > 12 || !this.pwd) {
          this.roomPwdError("房間密碼格式錯誤");
          return;
        }
      }

      //call api
      this.updateLeague();

      //if success :
      this.showMsg("房間狀態已變更為" + roomType);
      this.isPwdEditable = false;
      this.pwd = "";
    },
    roomPwdError(text) {
      this.dialogWarningData.title = "錯誤";
      this.dialogWarningData.actionName = "退回";
      this.dialogWarningData.actionFlag = 4;
      this.dialogWarningData.text = text;
      this.showWarningDialog();
    },
    showMsg(msg) {
      this.snackbarText = msg;
      this.snackbar = true;
    },
    closeDialog() {
      this.playersEditable = false;
      this.$emit("closeLeagueDetailDialog");
    },
    showWarningDialog() {
      this.dialogWarningShow = true;
    },
    closeWarningDialog() {
      this.dialogWarningData.title = "";
      this.dialogWarningData.text = "";
      this.dialogWarningData.actionName = "";
      this.dialogWarningShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
// @import "@/assets/style/fantasy/create_team";

.v-snack {
  bottom: 60px !important;
}

.card_header {
  background-image: linear-gradient(135deg, #eef1f3 0%, #e7eaed 100%);
}

.card_header.gc {
  background-image: linear-gradient(135deg, #faf2d7 0%, #e6d19f 100%);
}

.league_status {
  font-size: 18px;
  font-weight: bold;
  color: #1f1f1f;

  svg {
    color: #68747f;
  }
}

.btn_gray,
.btn_light_gray,
.btn_primary_blue,
.btn_red {
  cursor: pointer;
  padding: 5px 20px;
  font-size: 14px;
  color: white;
}

.btn_gray {
  background: #68747f;
}

.btn_light_gray {
  background: #c9c9c9;
}

.btn_primary_blue {
  background: #51769d;
}

.btn_red {
  background: #e28a8c;
}

.avator_icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  object-fit: cover;
  border-radius: 50%;
}

.avator_text {
  vertical-align: middle;
  margin-left: 5px;
}

.league_master {
  color: #51769d;
  font-size: 14px;
  font-weight: bolder;
}

.action {
  width: 50%;
  height: 100%;
}

input.search {
  max-width: 500px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  background: url("~@/assets/img/search.png") no-repeat right center;
  background-position: calc(100% - 10px) center;
  border-bottom: 1px solid #8eb0d6;
  font-size: 16px;
  padding: 8px 12px;
}

.edit_league_btn {
  margin-left: 20px;
  width: 80px;
}

tbody th {
  background: transparent;
  color: #515b65;
}

.btn_actions {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.arrow {
  display: inline-block;
  margin: auto;
  width: 50px;
  vertical-align: middle;
  flex-grow: 0;
}

.card {
  flex-grow: 1;

  .amount_container {
    width: 30%;
  }

  .amount {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    color: #515b65;
    margin-bottom: 0;
  }

  span.num {
    font-size: 20px;
    font-weight: bold;
    width: 50px;
  }

  .trans {
    color: #515b65;

    .title {
      color: #515b65;
      font-size: 13px !important;
    }
  }

  .current_player,
  .new_player {
    color: #c1c7ce;
    margin-right: 12px;
  }

  .current_player.selected {
    color: #515b65;
  }

  .new_player.selected {
    color: #c58330;
    font-weight: bold;
  }
}

@media (max-width: 959px) {
  .arrow {
    width: 30px;
  }
  .card {
    p,
    span.num {
      display: inline-block;
      font-size: 13px;
    }

    span.num {
      margin-left: 8px;
    }

    .trans {
      .title {
        font-size: 12px;
      }

      .current_player,
      .new_player {
        font-size: 13px;
        margin-right: 8px;
      }
    }
  }

  .btn_actions {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .btns_container {
    .btn_disable,
    .btn_water_blue,
    .btn_gray_hollow {
      flex-grow: 1;
    }
  }

  .card {
    .amount_container {
      width: 100%;
      line-height: 30px;
    }

    .amount {
      display: block;
      flex-grow: 1;
      justify-content: flex-start;
    }

    span.num {
      width: auto;
    }
  }

  .player_list_title {
    padding-bottom: 0;
  }

  .action {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 599px) {
  ::v-deep .v-dialog {
    margin: 0;
    max-height: 95%;
    width: 100%;
  }
}
</style>
