<template lang="pug">
.player_chip
  .chip.d-inline-block
    img.avatar_icon(
      v-show="!editable",
      :src="img != null && img.length > 0 ? img : gcAvatar"
    )
    svg-icon.delete_btn(
      v-show="editable",
      @click="deletePlayer",
      @mouseover="deleteBtnHover(true)",
      @mouseleave="deleteBtnHover(false)",
      :iconClass="deleteIconName",
      className="delete_icon"
    )
  .player_name.d-inline-block
    span.avatar_text {{ name }}
    .tip
      //- .triangle
      .rect.d-flex
        img.avatar_icon(:src="img != null && img.length > 0 ? img : gcAvatar")
        .player_info
          span.avatar_text.d-block {{ name }}
          span.email.d-block {{ email }}
          .btn.btn_waring(v-if="showEdit", @click="deletePlayer") 剔除
</template>

<script>
import gcAvatar from "@/assets/img/gc-avatar.png";

export default {
  props: {
    editable: Boolean,
    name: String,
    img: String,
    email: String,
    id: Number,
    showEdit: {
      type: Boolean,
      default: false,
    },
  },
  name: "PlayerChip",
  data() {
    return {
      deleteIconName: "ban-border",
      gcAvatar,
    };
  },
  methods: {
    deleteBtnHover(isHover) {
      if (isHover) {
        this.deleteIconName = "ban-solid";
      } else {
        this.deleteIconName = "ban-border";
      }
    },
    deletePlayer() {
      this.$emit("onDeletePlayerBtnClick", this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.player_chip {
  white-space: nowrap;
}

.avatar_icon {
  vertical-align: middle;
  object-fit: cover;
  border-radius: 50%;
}

.chip {
  .avatar_icon {
    width: 24px;
    height: 24px;
  }
}

.delete_btn {
  cursor: pointer;
}

.delete_icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

.player_name {
  position: relative;
  cursor: pointer;

  .avatar_text {
    vertical-align: middle;
    margin-left: 4px;
    cursor: pointer;
  }

  &:hover .tip {
    display: block;
  }
}

.tip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  display: none;

  .rect {
    position: relative;
    top: 10px;
    width: auto;
    height: auto;
    background: white;
    // border-radius: 10px;
    padding: 16px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

    &:after {
      position: absolute;
      content: "";
      top: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0px 10px 14px 10px;
      border-color: transparent transparent white transparent;
    }
  }

  .avatar_icon {
    width: 64px;
    height: 64px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }

  .player_info {
    margin-left: 8px;
    color: #1f1f1f;

    .avatar_text {
      font-weight: bold;
    }
  }
}

.btn_waring {
  background-color: #e28a8c;
  padding: 3px 5px;
  color: white;
}
</style>