<template lang="pug">
v-dialog(v-model="dialogPrivateKey", :scrollable="true", :max-width="maxWidth")
  v-card
    v-card-title.justify-center
      .text-h5 請輸入房間密碼
      font-awesome-icon.league_private.ml-2(:icon="['fas', 'key']")

    v-card-text.text-center.mt-3
      v-text-field(
        v-model="pwd",
        :rules="[rules.required, rules.pwdRegax]",
        counter,
        maxlength="6",
        hint="請輸入6字密碼(限英文、數字)"
      )
    v-card-actions
      v-spacer
      v-btn(outlined, color="dark-1", text="", @click="closeDialog") 取消
      v-btn(outlined, color="dark-1", text="", @click="sendLeaguePwd") 確定
</template>

<script>
export default {
  name: "DialogPrivateKey",
  props: {
    title: String,
    dialogPrivateKey: Boolean,
    maxWidth: {
      default: 290,
      type: Number,
    },
  },
  data() {
    return {
      pwd: "",
      rules: {
        required: (value) => !!value || "欄位必填",
        // name: (v) => v.length <= 12 || "請輸入最多不超過12個字",
        pwdRegax: (v) => {
          const pattern = /^[a-zA-Z0-9]+$/;
          if (!pattern.test(v)) {
            return "請輸入數字或英文";
          }

          if (v.length != 6) {
            return "請輸入6字密碼";
          }

          return true;
        },
      },
    };
  },
  methods: {
    sendLeaguePwd() {
      this.$emit("sendLeaguePwd", this.pwd);
    },
    closeDialog() {
      this.$emit("closePrivateKeyDialog");
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 599px) {
  ::v-deep .v-dialog {
    margin: 0;
    max-height: 95%;
    width: 100%;
  }
}
</style>