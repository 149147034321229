<template lang="pug">
.league_card.mb-3(
  @click="showLeagueDetailDialog",
  :class="{ gc: leagueData.is_official }"
)
  .league_blocks.d-flex.flex-column.flex-md-row
    .league_left_block
      .league_title.d-flex.align-center
        svg-icon.league_icon(
          v-if="leagueData.is_official",
          iconClass="gc_logo"
        )
        .league_name.ml-1 {{ leagueData.name }}
        font-awesome-icon.league_private.ml-2(
          v-if="!leagueData.is_private",
          :icon="['fas', 'globe-americas']"
        )
        font-awesome-icon.league_private.ml-2(
          v-if="leagueData.is_private",
          :icon="['fas', 'key']"
        )
      .league_note.mt-0.mt-md-3.d-flex.d-md-block.flex-column.align-end
        .inline.d-block.d-md-inline-block
          .league_room_number.d-inline-block
            font-awesome-icon.mr-1(:icon="['fas', 'warehouse']")
            span {{ leagueData.id }}
          .league_status.ml-4.d-inline-block.d-md-none(v-if="!allLeagues")
            font-awesome-icon.mr-2(:icon="['fas', 'info-circle']")
            span {{ getLeagueStatus }}
        .league_entrance_fee.ml-3.d-inline-block(v-if="allLeagues")
          font-awesome-icon.mr-1(:icon="['fas', 'ticket-alt']")
          span {{ leagueData.entry_fee }}
        .league_reward.ml-3.d-none.d-md-inline-block
          font-awesome-icon.mr-1(:icon="['fas', 'award']")
          span {{ leagueData.max_reward }}
        .league_period.d-none.d-md-block
          .league_created_date.d-inline-block.mt-2(
            v-if="leagueData.state == 'NEW'"
          ) 開賽時間 {{ leagueData.start_date }}
          .league_created_date.d-inline-block.mt-2(
            v-if="leagueData.state != 'NEW'"
          ) 結束時間 {{ leagueData.end_date }}
          .league_master.ml-3.d-inline-block(v-if="leagueData.is_owner")
            font-awesome-icon.mr-2(:icon="['fas', 'user']")
            span 你是聯賽管理員

    .league_right_block.d-flex.align-center.justify-space-between
      .league_left_wrap
        .league_data.d-flex
          .league_users
            font-awesome-icon.mr-2(:icon="['fas', 'users']")
            span {{ leagueData.current_team_count }}/{{ leagueData.max_team_count }} 人
          .league_status.ml-4.d-none.d-md-block(v-if="!allLeagues")
            font-awesome-icon.mr-2(:icon="['fas', 'info-circle']")
            span {{ getLeagueStatus }}
          .league_reward.ml-3.d-md-none
            font-awesome-icon.mr-1(:icon="['fas', 'award']")
            span {{ leagueData.max_reward }}
        .league_period.d-md-none
          .league_created_date.d-inline-block.mt-2(
            v-if="leagueData.state == 'NEW'"
          ) 開賽時間 {{ leagueData.start_date }}
          .league_created_date.d-inline-block.mt-2(
            v-if="leagueData.state != 'NEW'"
          ) 結束時間 {{ leagueData.end_date }}
          .league_master.ml-3.d-inline-block(v-if="leagueData.is_owner")
            font-awesome-icon.mr-2(:icon="['fas', 'user']")
            span 你是聯賽管理員
      v-spacer
      .rect_btns.d-flex.align-center
        .btn_gray.d-none.d-md-block(
          v-if="!allLeagues && leagueData.state != 'NEW'",
          @click.stop="showLeagueRankingDialog"
        ) 排名
        svg-icon.d-md-none(
          iconClass="league_ranking",
          className="league_ranking",
          v-if="!allLeagues && leagueData.state != 'NEW'",
          @click.stop="showLeagueRankingDialog"
        ) 
        .btn_primary_blue.ml-2.d-none.d-md-block(
          v-if="this.leagueData.state != 'NEW'",
          @click.stop="showBoxScoreDialog"
        ) BoxScore
        svg-icon.ml-2.d-md-none(
          iconClass="boxscore_icon",
          className="boxscore_icon",
          v-if="this.leagueData.state != 'NEW'",
          @click.stop="showBoxScoreDialog"
        ) 
  .receive_award_btn.text-center(
    v-if="reward",
    @click="getReward(leagueData.id)"
  ) 領取
</template>

<script>
export default {
  name: "LeagueCard",
  props: {
    leagueData: Object,
    allLeagues: Boolean,
    reward: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    getLeagueStatus() {
      switch (this.leagueData.state) {
        case "NEW":
          return "建立中";
        case "IN_SEASON":
          return "進行中";
        case "END":
          return "已結束";
        default:
          return "";
      }
    },
  },
  methods: {
    showLeagueDetailDialog() {
      this.$emit("showLeagueDetailDialog", this.leagueData, this.leagueData.id);
    },
    getReward(leagueId) {
      this.$emit("showGetCoinReceivingDialog", leagueId);
    },
    showLeagueRankingDialog() {
      this.$emit(
        "showLeagueRankingDialog",
        this.leagueData,
        this.leagueData.id
      );
    },
    showBoxScoreDialog() {
      this.$emit("showBoxScoreDialog", this.leagueData);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/fantasy/my_league/league_card";
</style>
