<template lang="pug">
.item_with_icon
  .title.d-flex.align-center
    font-awesome-icon.mr-2(:icon="['fas', iconName]")
    span.title_text {{ title }}
    v-btn.mr-2(
      v-if="actionBtn && !actionBtnStatus",
      @click="action",
      text,
      icon,
      color="lighten-2"
    )
      font-awesome-icon(:icon="['fas', 'pen']")
    v-spacer
    .action_btns(v-if="actionBtn && actionBtnStatus")
      v-btn.mr-2(@click="save", x-small, color="secondary", depressed) 儲存
      v-btn.mr-2(@click="action", x-small, color="grey", depressed, dark) 取消
  .text.mt-2
    slot(name="content") {{ content }}
</template>

<script>
export default {
  name: "ItemWithIcon",
  props: {
    title: String,
    iconName: String,
    content: String,
    actionBtn: {
      default: false,
      type: Boolean,
    },
    actionBtnStatus: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  methods: {
    action() {
      this.$emit("itemWithIconAction");
    },
    save() {
      this.$emit("itemWithIconSave");
    },
  },
};
</script>

<style lang="scss" scoped>
.title_text {
  font-weight: bold;
  color: #1f1f1f;
  font-size: 16px;
}

.title {
  svg {
    height: 16px;
    color: #a2b5ca;
  }
}

.v-btn {
  svg {
    height: 16px;
    color: #51769d;
  }
}

.text {
  color: #6a747e;
}
</style>