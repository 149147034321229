<template lang="pug">
v-dialog(
  v-model="dialogBoxScore",
  :scrollable="true",
  :max-width="1440",
  persistent
)
  v-card
    v-card-title.flex-column.justify-center.pa-0
      .team_stats_header.d-flex.flex-column.flex-md-row
        .team_states_menu.d-flex.align-center
          .team_states_tabs
            v-tab(:ripple="false") BoxScore
        .date_picker.d-flex.align-center.justify-space-around
          svg-icon(
            @click="toPrevDate",
            iconClass="arrow_left_round_rect",
            className="arrow_left_round_rect"
          )
          span(@click="openDateDialog") {{ date }}
          svg-icon(
            @click="toNextDate",
            iconClass="arrow_right_round_rect",
            className="arrow_right_round_rect"
          )
    v-card-text.text-center.pa-0
      v-container.pa-0
        .team_stats.pb-10
          .team_stats_body.py-3.px-3.py-md-5.px-md-9
            v-row.px-3(justify="space-between")
              v-col.title.pl-0(v-if="boxScoreData", cols="auto")
                h3 {{ boxScoreData.name }}
              v-col.pr-0(cols="auto")
                .game_status(
                  :class="[{ ready: userTeam.date_state == 'NEW' }, { going: userTeam.date_state == 'LOCK' }, { ending: userTeam.date_state == 'END' }]"
                ) {{ getGameStatus(userTeam.date_state) }}
            v-row.undraggable_table.table_row.mt-0
              v-col.table.f_table.local.table_col(cols="12")
                table
                  thead
                    tr.top_head
                      sticky-column.bdl.bdr(
                        colspan="2",
                        style="width: 150px; min-width: 150px",
                        :offset="0"
                      )
                      th.bdr(colspan="2") 
                      th.bdr(colspan="3") 價值
                      th.bdr(colspan="2") 
                      th.bdr(colspan="3") 投籃
                      th.bdr(colspan="3") 三分
                      th.bdr(colspan="3") 罰球
                      th.bdr(colspan="3") 籃板
                      th.bdr(colspan="5") 
                    tr.bottom_head
                      sticky-column.bdl(
                        style="width: 80px; min-width: 80px",
                        :offset="0"
                      ) 位置
                      sticky-column.bdr2(
                        style="width: 70px; min-width: 70px",
                        :offset="80"
                      ) 球員
                      th.bdr 隊伍
                      th.bdr 對手
                      th 季前
                      th 目前
                      th.bdr 趨勢
                      th.bdr 出賽
                      th.bdr 時間
                      th 命中
                      th 出手
                      th.bdr 命中率
                      th 命中
                      th 出手
                      th.bdr 命中率
                      th 命中
                      th 出手
                      th.bdr 命中率
                      th 進攻
                      th 防守
                      th.bdr 籃板
                      th 助攻
                      th 抄截
                      th 火鍋
                      th 失誤
                      th 得分
                  tbody
                    tr(
                      v-for="(player, index) in statCurrentPlayerList",
                      :key="index",
                      :class="{ highlight_team: player.is_calculated && userTeam.date_state == 'END' }"
                    )
                      sticky-column.bdr.bdl(
                        style="width: 80px; min-width: 80px",
                        :offset="0"
                      ) {{ player.arranged_position ? player.arranged_position : '-' }}
                      sticky-column.bdr2(
                        style="width: 70px; min-width: 70px",
                        :offset="80"
                      ) {{  ( player.name!=undefined?player.name:"") + ' ' + (player.position!=undefined?player.position:"")  }}
                      td.bdr {{ player.team_name }}
                      td.bdr {{ player.opponent_team_name }}
                      td {{ player.preseason_value }}
                      td {{ player.current_value }}
                      td.bdr(
                        :class="[(((player.current_value - player.preseason_value) / player.preseason_value) * 100).toFixed(1) > 0 ? 'text_secondary' : 'text_light_gray']"
                      ) {{ player.preseason_value ? (((player.current_value - player.preseason_value) / player.preseason_value) * 100).toFixed(1) + '%' : '' }}
                      td.bdr {{ player.record_matches }}
                      td.bdr {{ player.box.min >= 0 ? getMinutes(player.box.min) : '-' }}
                      td {{ player.box.fg_m != undefined ? player.box.fg_m : '-' }}
                      td {{ player.box.fg_a != undefined ? player.box.fg_a : '-' }}
                      td.bdr {{ player.box.fg_percent != undefined ? (player.box.fg_percent * 100).toFixed(1) + '%' : '-' }}
                      td {{ player.box.three_pts_m != undefined ? player.box.three_pts_m : '-' }}
                      td {{ player.box.three_pts_a != undefined ? player.box.three_pts_a : '-' }}
                      td.bdr {{ player.box.three_pts_percent != undefined ? (player.box.three_pts_percent * 100).toFixed(1) + '%' : '-' }}
                      td {{ player.box.ft_m != undefined ? player.box.ft_m : '-' }}
                      td {{ player.box.ft_a != undefined ? player.box.ft_a : '-' }}
                      td.bdr {{ player.box.ft_percent != undefined ? (player.box.ft_percent * 100).toFixed(1) + '%' : '-' }}
                      td {{ player.box.off_reb != undefined ? player.box.off_reb : '-' }}
                      td {{ player.box.def_reb != undefined ? player.box.def_reb : '-' }}
                      td.bdr {{ player.box.reb != undefined ? player.box.reb : '-' }}
                      td {{ player.box.ast != undefined ? player.box.ast : '-' }}
                      td {{ player.box.stl != undefined ? player.box.stl : '-' }}
                      td {{ player.box.blk != undefined ? player.box.blk : '-' }}
                      td {{ player.box.to != undefined ? player.box.to : '-' }}
                      td {{ player.box.pts != undefined ? player.box.pts : '-' }}
    v-card-actions.flex-column.pa-0
      .btn_container.w-100.text-center
        v-btn.my-3(outlined, color="dark-1", text="", @click="closeDialog") 關閉
</template>

<script>
import gcAvatar from "@/assets/img/gc-avatar.png";
import StickyColumn from "@/components/common/Table/StickyColumn";
import { getSeasonData, getMyTeamByTab } from "@/api/fantasy";

export default {
  name: "DialogBoxScore",
  props: {
    dialogBoxScore: Boolean,
    userMagic: String,
    boxScoreData: Object,
  },
  components: {
    StickyColumn,
  },
  data() {
    return {
      gcAvatar,
      seasonData: {},

      userToken: "",
      userTeam: {
        user: {},
      },

      allowedDates: [],
      date: "",
      pickerDate: null,
      isDialogShow: false,

      statCurrentPlayerList: [],

      statHasGameText: "",
      statHasGame: false,
      tab: 0,
    };
  },
  watch: {
    dialogBoxScore() {
      if (!this.dialogBoxScore) {
        this.userTeam = {
          user: {},
        };
        this.userToken = "";
        this.statCurrentPlayerList = [];
      } else {
        this.userToken = this.userMagic;
      }
    },
    userToken() {
      if (this.userToken != "") {
        this.getSeasonData();
      }
    },
    date() {
      this.getMyTeamByTab();
    },
  },
  methods: {
    getSeasonData() {
      let temp = {
        state: "IN_SEASON",
      };
      getSeasonData(temp).then((response) => {
        this.seasonData = response.data[0];
        if (this.seasonData.focus_date <= this.boxScoreData.end_date) {
          this.date = this.seasonData.focus_date;
        } else {
          this.date = this.boxScoreData.end_date;
        }
        this.getAllowedDates();
        this.getMyTeamByTab();
      });
    },
    async getMyTeamByTab() {
      this.dialogLoadingShow = true;

      let temp = {
        season_id: this.seasonData.id,
        date: this.date,
        tab: "STATS",
      };
      await getMyTeamByTab(temp).then((response) => {
        this.statHasGameText = response.data.date_state;
        this.userTeam = response.data;
        this.getCurrentPlayer();
      });
    },
    getCurrentPlayer() {
      let statPlayerGList = [];
      let statPlayerFList = [];
      let statPlayerCList = [];
      let statPlayerUList = [];
      let statForiegnUsedPlayerList = [];
      let statPlayerUnusedList = [];
      let statForiegnUnusedPlayerList = [];

      this.statCurrentPlayerList = [];

      for (let index in this.userTeam.team) {
        let temp = this.userTeam.team[index];
        switch (temp.arranged_position) {
          case "G":
            statPlayerGList.push(temp);
            break;
          case "F":
            statPlayerFList.push(temp);
            break;
          case "C":
            statPlayerCList.push(temp);
            break;
          case "U":
            statPlayerUList.push(temp);
            break;
          case "FOREIGN":
            if (temp.is_calculated) {
              statForiegnUsedPlayerList.push(temp);
            } else {
              statForiegnUnusedPlayerList.push(temp);
            }
            break;
          default:
            statPlayerUnusedList.push(temp);
            break;
        }
      }
      if (statPlayerGList.length == 0) {
        let tempG = {
          arranged_position: "G",
          is_calculated: true,
          box: {},
        };
        this.statCurrentPlayerList.push(tempG);
      } else {
        this.statCurrentPlayerList.push(...statPlayerGList);
      }

      if (statPlayerFList.length == 0) {
        let tempF = {
          arranged_position: "F",
          is_calculated: true,
          box: {},
        };
        this.statCurrentPlayerList.push(tempF);
      } else {
        this.statCurrentPlayerList.push(...statPlayerFList);
      }

      if (statPlayerCList.length == 0) {
        let tempC = {
          arranged_position: "C",
          is_calculated: true,
          box: {},
        };
        this.statCurrentPlayerList.push(tempC);
      } else {
        this.statCurrentPlayerList.push(...statPlayerCList);
      }

      if (statPlayerUList.length == 0) {
        let tempU = {
          arranged_position: "U",
          is_calculated: true,
          box: {},
        };
        this.statCurrentPlayerList.push(tempU);
      } else {
        this.statCurrentPlayerList.push(...statPlayerUList);
      }

      this.statCurrentPlayerList.push(...statForiegnUsedPlayerList);
      this.statCurrentPlayerList.push(...statPlayerUnusedList);
      this.statCurrentPlayerList.push(...statForiegnUnusedPlayerList);

      if (this.userTeam.date_state == "END") {
        let temp = {
          arranged_position: "總計",
          is_calculated: true,
          box: this.userTeam.box,
        };

        this.statCurrentPlayerList.push(temp);
      }
    },
    getAllowedDates() {
      this.allowedDates = [];
      let start = this.seasonData.valid_dates.indexOf(
        this.boxScoreData.start_date
      );
      let end = this.seasonData.valid_dates.indexOf(this.boxScoreData.end_date);
      for (let index in this.seasonData.valid_dates) {
        if (index >= start && index <= end) {
          this.allowedDates.push(this.seasonData.valid_dates[index]);
        }
      }
    },
    getGameStatus(gameStatus) {
      switch (gameStatus) {
        case "NO_GAME":
          return "沒有比賽";
        case "NEW":
          return "比賽未開始";
        case "LOCK":
          return "比賽已鎖定";
        case "END":
          return "比賽結束";
        default:
          return "沒有比賽";
      }
    },
    closeDialog() {
      this.$emit("closeBoxScoreDialog");
    },
    openDateDialog() {
      this.isDialogShow = true;
    },
    closeDateDialog() {
      this.isDialogShow = false;
    },
    toPrevDate() {
      let currentDateIndex = this.allowedDates.indexOf(this.date);
      if (currentDateIndex != -1) {
        if (currentDateIndex != 0) {
          this.date = this.allowedDates[currentDateIndex - 1];
        }
      }
    },
    toNextDate() {
      let currentDateIndex = this.allowedDates.indexOf(this.date);
      if (currentDateIndex != -1) {
        if (currentDateIndex != this.allowedDates.length - 1) {
          this.date = this.allowedDates[currentDateIndex + 1];
        }
      }
    },
    getMinutes(temp) {
      let min = parseInt(temp / 60);
      let second = (temp % 60).toFixed(0);
      if (second < 10) {
        return min + ":0" + second;
      }
      return min + ":" + second;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/fantasy/overview";

tbody {
  th.sticky {
    color: #51769d;
    font-weight: normal;
  }
}
.foreign {
  tbody {
    th.sticky {
      color: #1f1f1f;
      font-weight: normal;
    }
  }
}
img.user_avater {
  border-radius: 50%;
  object-fit: cover;
  background: #d8d8d8;
  flex-shrink: 0;
}

.avater_lg {
  width: 116px;
  height: 116px;
  border: 2px solid #ffffff;
  box-shadow: 0 0 30px 0 rgba(156, 172, 194, 0.5);
}

select {
  option {
    color: black;
  }
}
.level {
  color: #8eb0d6;
}

.user_data {
  background: #edf6f9;
  width: 40%;

  li {
    line-height: 30px;
  }

  .data_value {
    color: #36475a;
    font-weight: 700;
    margin-left: 10px;
  }
}

.team_stats {
  box-shadow: 0 0 30px 0 #e1e8f0;
  width: 100%;
  overflow: hidden;
}

.team_stats_header {
  width: 100%;
  height: 64px;
}

.team_states_menu {
  width: 70%;
  height: 100%;
  background: #6c7a8e;
  padding-left: 12px;

  .v-tab {
    color: #e0efff !important;
    font-weight: 500;
    font-size: 14px;
  }

  .v-tab:not(:last-child) {
    border-right: 1px solid rgba(white, 0.5);
  }

  .v-tab--active {
    color: #e0efff !important;
    font-weight: bold;
  }
}

.date_picker {
  background-color: #f7f7f7;
  width: 30%;
  height: 100%;

  svg {
    color: #6c7a8e;
  }

  svg,
  span {
    cursor: pointer;
  }
}

.team_stats_body {
  width: 100%;
  background: white;
}

.users_team {
  span {
    display: inline-block;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.create {
  .btn {
    width: 160px;
    margin-bottom: 120px;
    margin-left: auto;
    margin-right: auto;
  }
}

.empty_cover {
  width: 100%;
  position: relative;

  img {
    width: 100%;
    height: auto;
    opacity: 0.8;
  }

  .msg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.game_status {
  color: #ababab;
  border: rgba(#ababab, 0.5) 1px solid;
  padding: 5px;
  font-size: 12px;
}

.game_status.ready {
  color: #e4a86c;
  border-color: rgba(#e4a86c, 0.5);
}

.game_status.going {
  color: #e07575;
  border-color: rgba(#e07575, 0.5);
}

.game_status.ending {
  color: #6b6b6b;
  border-color: rgba(#6b6b6b, 0.5);
}

@media (max-width: 959px) {
  .v-snack {
    bottom: 60px !important;
  }

  .team_states_menu {
    width: 100%;
  }

  .team_stats {
    border-radius: 0 !important;
    box-shadow: none;
  }

  .date_picker {
    width: 100%;
    min-height: 40px;
  }

  .team_stats_header {
    height: auto;
  }

  .user_desc_wrapper {
    display: flex;

    .level {
      margin-left: 10px;
    }
  }

  .users_team {
    font-size: 14px;
    line-height: 2rem;
  }

  .user_data {
    background: transparent;
    padding: 0px !important;
    border-top: 1px solid #e3e3e3;
    width: 100%;

    li {
      width: 50%;
      display: inline-block !important;
      line-height: initial;
    }
  }

  .data_value {
    font-size: 12px;
  }

  .user_data_title {
    display: inline-block;
    letter-spacing: initial !important;
    line-height: initial;
  }

  .team_states_menu {
    padding-left: 0px;
  }

  .team_states_tabs {
    width: 100%;
    height: 48px;
    justify-content: center;
    display: flex;
  }

  ::v-deep .v-card__actions {
    box-shadow: 0 -2px 14px 0 rgb(200 200 200 / 50%);
    z-index: 1;
  }
}
@media (max-width: 599px) {
  ::v-deep .v-dialog {
    margin: 0;
    max-height: 95%;
  }
}
</style>
