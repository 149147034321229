<template lang="pug">
v-dialog.mb-15(
  v-model="dialogLeagueCreate",
  :scrollable="true",
  max-width="100%",
  persistent
)
  v-card
    v-container.container--fluid.card_header
      v-card-title.flex-column.justify-center.pa-0
        .league_title.d-flex
          .league_name.ml-1 建立聯賽
    v-card-text.pa-0.px-sm-12.py-sm-10
      v-container.league_create_wrapper.mt-4.pt-8.pb-15.px-4.px-sm-10
        v-row
          v-col(cols="12", md="6")
            item-with-icon.pa-3(
              style="background: #f4f7fb",
              iconName="dollar-sign",
              title="聯盟名稱"
            )
              template(#content)
                v-text-field(
                  v-model="name",
                  :rules="[rules.required]",
                  counter,
                  maxlength="20",
                  hint="請輸入20字以內的聯盟名稱"
                )
            item-with-icon.pa-3.mt-3(
              style="background: #f4f7fb",
              iconName="users",
              title="人數限制"
            )
              template(#content)
                v-text-field(
                  v-model="limitPeople",
                  type="text",
                  hint="人數限制：4~1000人",
                  :rules="[rules.required, rules.limitRegax, rules.peopleLimit]"
                )
            item-with-icon.pa-3.mt-3(
              style="background: #f4f7fb",
              iconName="flag",
              title="比賽天數"
            )
              template(#content)
                v-text-field.input-center(
                  v-model="limitGames",
                  readonly,
                  type="text",
                  :rules="[rules.required, rules.limitRegax]"
                )
                  template(v-slot:prepend="")
                    font-awesome-icon(
                      :icon="['fas', 'minus']",
                      @click="minusLimitGames",
                      style="cursor: pointer"
                    )
                  template(v-slot:append-outer="")
                    font-awesome-icon(
                      :icon="['fas', 'plus']",
                      @click="plusLimitGames",
                      style="cursor: pointer"
                    )

            item-with-icon.pa-3.mt-3(
              style="background: #f4f7fb",
              iconName="dollar-sign",
              title="最高獎金"
            )
              template(#content)
                v-select(
                  v-model="reward",
                  :items="rewardList",
                  :item-text="'name'",
                  return-object,
                  label="請選擇"
                )
          v-col.relative(cols="12", md="6")
            item-with-icon.pa-3(
              style="background: #f4f7fb",
              iconName="donate",
              title="入場費"
            )
              template(#content)
                //(max_reward * 1.25) / player_num 無條件進位至百位低於 200, 就取 200
                p {{ getFee }}
            item-with-icon.pa-3.mt-3(
              style="background: #f4f7fb",
              iconName="calendar",
              title="開賽日期"
            )
              template(#content)
                .date.d-flex.align-center
                  .date_picker.d-flex.align-center.justify-space-between
                    svg-icon(
                      @click="toPrevDate",
                      iconClass="arrow_left_round_rect",
                      className="arrow_left_round_rect",
                      :class="{ arrow_disable: getDateArrowState('PREV') }"
                    )
                    span(@click="openDateDialog") {{ date }}
                    svg-icon(
                      @click="toNextDate",
                      iconClass="arrow_right_round_rect",
                      className="arrow_right_round_rect",
                      :class="{ arrow_disable: getDateArrowState('NEXT') }"
                    ) 

            item-with-icon.pa-3.mt-3(
              style="background: #f4f7fb; margin-bottom: 150px",
              iconName="key",
              title="是否為公開房間"
            )
              template(#content)
                v-radio-group(v-model="isPublic", row="")
                  v-radio(label="公開", value="public")
                  v-radio(label="私人", value="secret")

                v-text-field(
                  v-if="isPublic == 'secret'",
                  v-model="pwd",
                  :rules="[rules.required, rules.pwdRegax]",
                  counter,
                  maxlength="12",
                  hint="請輸入12字以內的密碼(限英文、數字)"
                )
            .calculation
              .fee.d-flex.justify-space-between
                span 開場費
                span.font-weight-bold - {{ getFee }}
              .fee.d-flex.justify-space-between
                span 剩餘資產
                span 
                  span.text_gold.font-weight-bold {{ getProperty }}
                  span.ml-1 / {{ toThousands(userInfo.fantasy_coin) }}
              v-divider.mt-4
              .rect_btns.d-flex.justify-end.mt-3
                .btn_primary_blue(@click="onCreateBtnClick") 建立
                .btn_gray.ml-2(@click="onAbandonBtnClick") 放棄
    v-card-actions.justify-center
      v-btn(
        small,
        outlined,
        color="dark-1",
        text="",
        @click="onAbandonBtnClick"
      ) close

  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-warning(
    :title="dialogWarningData.title",
    @closeWarningDialog="closeWarningDialog",
    @action="onActionBtnClick",
    :actionName="dialogWarningData.actionName",
    :dialogWarning="dialogWarningShow",
    :actionFlag="dialogWarningData.actionFlag"
  )
    template(#content)
      p {{ dialogWarningData.text }}
  v-dialog(v-model="isDialogShow", width="500")
    v-date-picker.mt-4(
      v-model="date",
      locale="zh-tw",
      :picker-date.sync="pickerDate",
      :allowedDates="getAllowedDates",
      full-width,
      @click:date="closeDateDialog"
    )
  dialog-error(
    :title="dialogErrorData.title",
    @closeErrorDialog="closeErrorDialog",
    :dialogError="dialogErrorShow"
  )
    template(#content)
      p {{ dialogErrorData.text }}
</template>

<script>
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogError from "@/components/dialog/DialogError";
import DialogWarning from "@/components/dialog/DialogWarning";
import DialogLoading from "@/components/dialog/DialogLoading";
import ItemWithIcon from "@/components/fantasy/my_league/ItemWithIcon";
import { getSeasonData, checkMyTeam, createLeague } from "@/api/fantasy";
import { getUserInfo } from "@/api/user";

export default {
  name: "DialogLeagueCreate",
  props: {
    needUpdate: Boolean,
    title: String,
    dialogLeagueCreate: Boolean,
    icon: String,
  },
  components: {
    DialogSuccess,
    DialogError,
    DialogWarning,
    DialogLoading,
    ItemWithIcon,
  },
  data() {
    return {
      userInfo: {},
      seasonData: {},
      isTeamExist: null,
      myTeamInfo: {},
      name: "",
      pwd: "",
      fee: 0,
      isPublic: "public", //public, secret
      limitPeople: 5,
      limitGames: 1,
      maxGames: 10,
      rules: {
        required: (value) => !!value || "欄位必填",
        // name: (v) => v.length <= 12 || "請輸入最多不超過12個字",
        pwdRegax: (v) => {
          const pattern = /^[a-zA-Z0-9]+$/;
          if (!pattern.test(v)) {
            return "請輸入數字或英文";
          }

          if (v.length > 12) {
            return "請輸入12字以內的密碼";
          }

          return true;
        },
        peopleLimit: (v) => {
          if (v < 4) {
            return "人數不可少於4人";
          }
          if (v > 1000) {
            return "人數不可大於1000人";
          }

          return true;
        },
        limitRegax: (v) => {
          const pattern = /^[0-9]*$/;
          if (!pattern.test(v)) {
            return "請輸入數字";
          }
          if (v < 1) {
            return "場數不可小於1人";
          }
          return true;
        },
      },
      reward: "",
      rewardList: ["2000", "1500", "1000"],

      date: "",
      pickerDate: null,
      isDialogShow: false,

      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogWarningShow: false,
      dialogWarningData: {
        title: "",
        text: "",
        actionName: "",
        actionFlag: -1,
      },

      dialogErrorShow: false,
      dialogErrorData: {
        title: "",
        text: "",
      },
    };
  },
  watch: {
    needUpdate() {
      if (this.needUpdate) {
        this.getAchievement();
        this.$emit("setAchievementUpdated");
      }
    },
    isPublic() {
      if (this.isPublic == "public") {
        this.pwd = "";
      }
    },
    reward() {
      if (this.reward == "") {
        this.fee = 0;
      } else {
        this.fee =
          Math.ceil((this.reward * 1.25) / this.limitPeople / 100) * 100;
        if (this.fee < 200) {
          this.fee = 200;
        }
      }
    },
    limitPeople() {
      if (this.reward == "") {
        this.fee = 0;
      } else {
        this.fee =
          Math.ceil((this.reward * 1.25) / this.limitPeople / 100) * 100;
        if (this.fee < 200) {
          this.fee = 200;
        }
      }
    },
    dialogLeagueCreate() {
      if (this.dialogLeagueCreate) {
        this.getSeasonData();
        this.getUserInfo();
      }
    },
  },
  methods: {
    getUserInfo() {
      getUserInfo().then((response) => {
        this.userInfo = response.data;
      });
    },
    getSeasonData() {
      let temp = {
        state: "IN_SEASON",
      };
      getSeasonData(temp).then((response) => {
        this.seasonData = response.data[0];
        console.log("response.data=", response.data);
        if (this.seasonData != undefined) {
          this.checkMyTeam();
        }
      });
    },
    checkMyTeam() {
      if (this.$store.state.user.accessToken) {
        let temp = {
          season_id: this.seasonData.id,
        };
        checkMyTeam(temp).then((response) => {
          this.isTeamExist = response.data.has_team;
          if (this.isTeamExist) {
            this.date = this.seasonData.focus_date;
            this.getAllowedDates();
          }
          this.myTeamInfo = response.data.team;
        });
      }
    },
    createLeague() {
      let pwd = "";

      if (this.isPublic == "secret") {
        pwd = this.pwd;
      }

      let temp = {
        season_id: this.seasonData.id,
        start_date: this.date,
        name: this.name,
        room_key: pwd,
        game_count: this.limitGames,
        max_reward: this.reward,
        max_team_count: this.limitPeople,
        entry_fee: this.fee,
      };
      createLeague(temp).then(() => {
        this.closeDialog();
        this.showSuccessDialog("成功", "聯盟 " + name + " 建立成功！");
        this.$emit("reloadLeagueByCancel");
        this.clearData();
      });
    },
    getAllowedDates(val) {
      if (val && this.seasonData.remain_dates.includes(val)) {
        return true;
      }
      return false;
    },
    getDateArrowState(direction) {
      if (this.seasonData.remain_dates != undefined) {
        let currentDateIndex = this.seasonData.remain_dates.indexOf(this.date);
        if ("PREV" == direction) {
          if (currentDateIndex == 0) {
            return true;
          } else {
            return false;
          }
        } else if ("NEXT" == direction) {
          if (currentDateIndex == this.seasonData.remain_dates.length - 1) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    },
    toPrevDate() {
      if (this.seasonData.remain_dates != undefined) {
        let currentDateIndex = this.seasonData.remain_dates.indexOf(this.date);
        if (currentDateIndex != -1) {
          if (currentDateIndex != 0) {
            this.date = this.seasonData.remain_dates[currentDateIndex - 1];
            this.limitGames = 1;
          }
        }
      }
    },
    toNextDate() {
      if (this.seasonData.remain_dates != undefined) {
        let currentDateIndex = this.seasonData.remain_dates.indexOf(this.date);
        if (currentDateIndex != -1) {
          if (currentDateIndex != this.seasonData.remain_dates.length - 1) {
            this.date = this.seasonData.remain_dates[currentDateIndex + 1];
            this.limitGames = 1;
          }
        }
      }
    },
    onActionBtnClick() {
      switch (this.dialogWarningData.actionFlag) {
        case 0:
          this.createLeague();
          this.dialogWarningShow = false;
          break;
        case 1:
          this.dialogWarningShow = false;
          this.closeDialog();
          this.clearData();

          break;
      }
    },
    onCreateBtnClick() {
      const limitPattern = /^[0-9]*$/;

      if (
        this.name.trim() == "" ||
        this.reward.trim() == "" ||
        this.limitPeople < 4 ||
        this.limitPeople > 1000 ||
        !limitPattern.test(this.limitPeople) ||
        !limitPattern.test(this.limitGames) ||
        this.limitGames < 1 ||
        this.checkRoomPwd()
      ) {
        this.showErrorDialog("資料錯誤", "請填寫完整房間資料！");
      } else {
        this.dialogWarningData.title = "提醒";
        this.dialogWarningData.text =
          "將從您帳戶內收取 " + this.fee + " Fantasy Coins";
        this.dialogWarningData.actionName = "確定";
        this.dialogWarningData.actionFlag = 0;

        this.dialogWarningShow = true;
      }
    },
    checkRoomPwd() {
      if (this.isPublic == "secret") {
        const pattern = /^[a-zA-Z0-9]+$/;
        if (!pattern.test(this.pwd) || this.pwd.length > 12 || !this.pwd) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    onAbandonBtnClick() {
      this.dialogWarningData.title = "警告";
      this.dialogWarningData.text = "確定要放棄建立聯盟嗎？";
      this.dialogWarningData.actionName = "確定";
      this.dialogWarningData.actionFlag = 1;

      this.dialogWarningShow = true;
    },
    clearData() {
      this.name = "";
      this.pwd = "";
      this.fee = 0;
      this.isPublic = "public";
      this.limitPeople = 5;
      this.limitGames = 1;
      this.reward = "";
      this.date = "";
    },
    plusLimitPeople() {
      this.limitPeople = this.limitPeople + 1;
    },
    minusLimitPeople() {
      if (this.limitPeople >= 3) {
        this.limitPeople = this.limitPeople - 1;
      }
    },
    plusLimitGames() {
      let currentDateIndex = this.seasonData.remain_dates.indexOf(this.date);

      if (currentDateIndex != -1) {
        var remainGames =
          this.seasonData.remain_dates.length - currentDateIndex;

        if (
          this.limitGames == remainGames ||
          this.limitGames == this.maxGames
        ) {
          return;
        }
      } else {
        return;
      }

      this.limitGames = this.limitGames + 1;
    },
    minusLimitGames() {
      if (this.limitGames >= 2) {
        this.limitGames = this.limitGames - 1;
      }
    },
    openDateDialog() {
      this.isDialogShow = true;
    },
    closeDateDialog() {
      this.isDialogShow = false;
    },
    closeDialog() {
      this.$emit("closeLeagueCreateDialog");
    },
    showWarningDialog() {
      this.dialogWarningShow = true;
    },
    closeWarningDialog() {
      this.dialogWarningData.title = "";
      this.dialogWarningData.text = "";
      this.dialogWarningShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
    showErrorDialog(title, text) {
      this.dialogErrorData.title = title;
      this.dialogErrorData.text = text;
      this.dialogErrorShow = true;
    },
    closeErrorDialog() {
      this.dialogErrorData.title = "";
      this.dialogErrorData.text = "";
      this.dialogErrorShow = false;
    },
    toThousands(num) {
      var temp = num + "";
      var result = "";
      while (temp.length > 3) {
        result = "," + temp.slice(-3) + result;
        temp = temp.slice(0, temp.length - 3);
      }
      if (temp) {
        result = temp + result;
      }
      return result;
    },
  },
  computed: {
    getFee() {
      if (isNaN(this.fee)) {
        return "計算錯誤";
      } else {
        return this.fee;
      }
    },
    getProperty() {
      if (isNaN(this.fee)) {
        return "計算錯誤";
      } else {
        return this.toThousands(this.userInfo.fantasy_coin - this.fee);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/fantasy/my_league/dialog_league_create";

::v-deep .v-dialog {
  width: 100%;
}
</style>
