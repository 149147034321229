<template lang="pug">
v-dialog.mb-15(
  v-model="dialogLeagueSearch",
  :scrollable="true",
  max-width="100%",
  persistent
)
  v-card
    v-container.container--fluid.card_header
      v-card-title.flex-column.justify-center.pa-0
        .league_title.d-flex
          .league_name.ml-1 搜尋聯賽
            .action.d-flex.align-center
        input.search(placeholder="輸入聯賽名稱或房間號碼", v-model="searchText")
    v-card-text.px-4.pb-10
      v-container.league_create_wrapper.pt-8.pb-15.px-0.px-md-10
        v-row
          v-col(cols="12")
            league-card(
              v-for="(card, index) in allLeagueData",
              :key="index",
              :leagueData="card",
              :allLeagues="true",
              @showLeagueDetailDialog="showLeagueDetailDialog(card)"
            )
    v-pagination(
      v-model="allLeagueCurrentPage",
      :length="allLeagueTotalPages",
      prev-icon="mdi-menu-left",
      next-icon="mdi-menu-right",
      circle
    )
    v-card-actions.justify-center
      v-btn(small, outlined, color="dark-1", text="", @click="closeDialog") close
</template>
<script>
import LeagueCard from "@/components/fantasy/my_league/LeagueCard";

import {
  getSeasonData,
  checkMyTeam,
  getFantasySeasonLeagueList,
} from "@/api/fantasy";

export default {
  name: "DialogLeagueSearch",
  props: {
    dialogLeagueSearch: Boolean,
  },
  components: {
    LeagueCard,
  },
  data() {
    return {
      isTeamExist: null,
      myTeamInfo: {},
      allLeagueData: [],
      searchText: "",

      allLeagueCurrentPage: 1,
      allLeagueTotalPages: 1,
      perPage: 10,
    };
  },
  watch: {
    dialogLeagueSearch() {
      if (!this.dialogLeagueSearch) {
        this.searchText = "";
        this.allLeagueData = [];
        this.allLeagueCurrentPage = 1;
        this.allLeagueTotalPages = 1;
      }
    },
    searchText() {
      this.allLeagueData = [];
      this.allLeagueCurrentPage = 1;
      this.allLeagueTotalPages = 1;
      if (this.searchText != "") {
        this.getFantasySeasonLeagueList();
      }
    },
  },
  created() {
    this.getSeasonData();
  },
  methods: {
    getSeasonData() {
      let temp = {
        state: "IN_SEASON",
      };
      getSeasonData(temp).then((response) => {
        this.seasonData = response.data[0];
        if (this.seasonData != undefined) {
          this.checkMyTeam();
        }
      });
    },
    checkMyTeam() {
      if (this.$store.state.user.accessToken) {
        let temp = {
          season_id: this.seasonData.id,
        };
        checkMyTeam(temp).then((response) => {
          this.isTeamExist = response.data.has_team;
          this.myTeamInfo = response.data.team;
        });
      }
    },
    getFantasySeasonLeagueList() {
      let temp = {
        season_id: this.seasonData.id,
        state: "NEW",
        page: this.allLeagueCurrentPage,
        page_size: this.perPage,
        order: "START_DATE",
        key: this.searchText,
      };
      getFantasySeasonLeagueList(temp).then((response) => {
        let res = response.data;
        this.allLeagueData = res.data;
        this.allLeagueTotalPages = res.total_page;
      });
    },
    showLeagueDetailDialog(leagueData) {
      this.$emit("showLeagueDetailDialog", leagueData, leagueData.id);
    },
    closeDialog() {
      this.$emit("closeLeagueSearchDialog");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/pagination";
::v-deep .v-dialog {
  width: 100%;
  height: 100%;
}

input.search {
  width: 66%;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  background: url("~@/assets/img/search.png") no-repeat right center;
  background-position: calc(100% - 10px) center;
  border-bottom: 1px solid #8eb0d6;
  font-size: 16px;
  padding: 8px 12px;
}

@media (max-width: 599px) {
  ::v-deep .v-dialog {
    margin: 0;
  }

  input.search {
    width: 100%;
  }
}
</style>
