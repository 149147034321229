<template lang="pug">
.my_league(v-if="$store.state.user.accessToken && isTeamExist")
  .user_info.py-7.d-flex.justify-start.justify-md-space-between.flex-column.flex-md-row
    .user_basis.d-flex.align-center
      .user.d-flex.flex-grow-1.align-center
        img.user_avater.avater_lg.d-none.d-md-block(
          v-if="!$store.state.user.accessToken || ($store.state.user.accessToken && !$store.state.user.photo)",
          :src="gcAvatar"
        )
        img.user_avater.avater_lg.d-none.d-md-block(
          v-if="$store.state.user.accessToken && $store.state.user.photo",
          :src="$store.state.user.photo"
        )
        span.users_team.ml-0.ml-md-6.flex-grow-1
          span.font-weight-bold.player {{ $store.state.user.nickname }}
          span.font-weight-bold.ml-1.mr-1 的聯賽
          svg-icon.ml-1(
            iconClass="mobile_ok",
            v-if="$store.state.user.isPhoneVerified"
          )
          svg-icon.ml-1(
            iconClass="profile_ok",
            v-if="$store.state.user.isProfileComplete"
          )
        .user_data_title.text-overline.d-md-none(
          style="font-size: 0.68rem !important; color: #8e8e8e"
        )

    .user_advanced.d-flex.flex-row-reverse.flex-md-row
      .achievement_and_wallet_container.ml-0.mx-md-3
        .rect.pb-0.pb-md-2
          .achievement.px-1.py-1.py-md-4(@click="showAchievementDialog")
            .content.d-flex.align-center
              svg-icon(
                iconClass="gc_achievement_icon",
                className="gc_achievement_icon"
              )
              .titles.d-flex.flex-column.justify-space-between
                span.text-body.text_gray.d-none.d-md-block ACHIEVEMENT
                span.text-body 我的成就
              v-spacer
        .rect.pt-0.pt-md-2
          .wallet.py-1.py-md-4(@click="toMemberCenter")
            .content.d-flex.align-center
              svg-icon(iconClass="gc_coin_icon", className="gc_coin_icon")
              .titles.d-flex.flex-column.justify-space-between
                span.text-body.text_gray.d-none.d-md-block FANTASY COINS
                h2.text-body {{ toThousands(userInfo.fantasy_coin) }}
              v-spacer

      .user_data.radius_s.py-4.px-7
        li.d-flex.justify-space-between.mt-1
          .user_data_title.text-overline(style="color: #8eb0d6")
            font-awesome-icon.mr-1.mr-md-2(
              :icon="['fas', 'angle-double-right']"
            )
            | 進行中聯賽
          span.data_value {{ seasonData.league_in_progress }}
        li.d-flex.justify-space-between.mt-1
          .user_data_title.text-overline(style="color: #6a72d6")
            font-awesome-icon.mr-1.mr-md-2(:icon="['fas', 'check-double']")
            | 已結束聯賽
          span.data_value {{ seasonData.league_end }}
        li.d-flex.justify-space-between.mt-1
          .user_data_title.text-overline(style="color: #b88ed6")
            font-awesome-icon.mr-1.mr-md-2(:icon="['fas', 'dollar-sign']")
            | 總獲得獎金
          span.data_value {{ seasonData.total_reward }}

  .league_stats.radius_l.bg_white.position-relative(
    :style="{ top: $store.state.navbarHeight > 48 ? '0px' : $store.state.navbarHeight + 'px' }"
  )
    .league_stats_header.d-flex.flex-column.flex-md-row
      .league_states_menu.d-flex.align-center
        .league_states_tabs
          v-tabs.position-relative(v-model="tab", ripple="false")
            v-tabs-slider(hidden="true")
            v-tab(:ripple="false") 
              | 聯賽列表
            v-tab(:ripple="false") 
              | 已參加
            v-tab(:ripple="false") 
              | 待領取
      .action.align-center.justify-end.pr-5.d-none.d-md-flex
        .btn.btn_water_blue.search_league_btn(@click="showLeagueSearchDialog") 搜尋聯賽
        .btn.btn_water_blue.create_league_btn(@click="showLeagueCreateDialog") 建立聯賽
    transition(name="page", mode="out-in")
      .league_stats.wrapper.pb-10.pa-0.py-md-5.px-md-9(v-if="tab == 0")
        .league_header_tools.d-flex.justify-space-between.align-center
          v-radio-group.justify-center(v-model="allLeagueRadioGroup", row)
            v-radio(
              v-for="(type, index) in typeList",
              :key="index",
              :label="type",
              :value="index"
            ) 
          .league_sorting
            .sort_btn.sort_reward(
              @click="clickSort(0)",
              :class="{ active: sortIndex == 0 }"
            )
              span 人數
              font-awesome-icon(
                :icon="['fas', 'long-arrow-alt-up']",
                :class="{ rotate: isSortAscending }"
              )
            .sort_btn.sort_bill(
              @click="clickSort(1)",
              :class="{ active: sortIndex == 1 }"
            )
              span 入場費
              font-awesome-icon(
                :icon="['fas', 'long-arrow-alt-up']",
                :class="{ rotate: isSortAscending }"
              )
            .sort_btn.sort_time(
              @click="clickSort(2)",
              :class="{ active: sortIndex == 2 }"
            )
              span 開始時間
              font-awesome-icon(
                :icon="['fas', 'long-arrow-alt-up']",
                :class="{ rotate: isSortAscending }"
              )
        .empty_alert.pb-5(v-if="isAllLeagueEmptyAlertShow")
          h5.text-center {{ allLeagueEmptyAlertText }}
        .league_all.pb-12.pb-md-0(
          v-if="allLeagueRadioGroup == 0",
          :style="{ height: $store.state.navbarHeight <= 48 ? $store.state.screenHeight - 280 + 'px' : 'auto' }"
        )
          league-card(
            v-for="(card, index) in allLeagueData",
            :key="index",
            :leagueData="card",
            :allLeagues="true",
            @showBoxScoreDialog="showBoxScoreDialog",
            @showLeagueDetailDialog="showLeagueDetailDialog"
          )
        .league_all.pb-12.pb-md-0(
          v-if="allLeagueRadioGroup == 1",
          :style="{ height: $store.state.navbarHeight <= 48 ? $store.state.screenHeight - 280 + 'px' : 'auto' }"
        )
          league-card(
            v-for="(card, index) in allLeagueData",
            :key="index",
            :leagueData="card",
            :allLeagues="true",
            @showBoxScoreDialog="showBoxScoreDialog",
            @showLeagueDetailDialog="showLeagueDetailDialog"
          )
        v-pagination(
          v-model="allLeagueCurrentPage",
          :length="allLeagueTotalPages",
          prev-icon="mdi-menu-left",
          next-icon="mdi-menu-right",
          circle
        )
    transition(name="page", mode="out-in")
      .league_stats.wrapper.pb-10.pa-0.py-md-5.px-md-9(v-if="tab == 1")
        .league_header_tools.d-flex.justify-space-between.align-center
          v-radio-group.justify-center(v-model="joinLeagueRadioGroup", row)
            v-radio(
              v-for="(status, index) in statusList",
              :key="index",
              :label="status",
              :value="index"
            ) 
        .empty_alert.pb-5(v-if="isJoinLeagueEmptyAlertShow")
          h5.text-center {{ joinLeagueEmptyAlertText }}
        .league_join.pb-12.pb-md-0(
          v-if="joinLeagueRadioGroup == 0",
          :style="{ height: $store.state.navbarHeight <= 48 ? $store.state.screenHeight - 280 + 'px' : 'auto' }"
        )
          league-card(
            v-for="(card, index) in joinLeagueData",
            :key="index",
            :leagueData="card",
            :allLeagues="false",
            @showBoxScoreDialog="showBoxScoreDialog",
            @showLeagueDetailDialog="showLeagueDetailDialog",
            @showLeagueRankingDialog="showLeagueRankingDialog"
          )
        .league_join.pb-12.pb-md-0(
          v-if="joinLeagueRadioGroup == 1",
          :style="{ height: $store.state.navbarHeight <= 48 ? $store.state.screenHeight - 280 + 'px' : 'auto' }"
        )
          league-card(
            v-for="(card, index) in joinLeagueData",
            :key="index",
            :leagueData="card",
            :allLeagues="false",
            @showBoxScoreDialog="showBoxScoreDialog",
            @showLeagueDetailDialog="showLeagueDetailDialog",
            @showLeagueRankingDialog="showLeagueRankingDialog"
          )
        .league_join.pb-12.pb-md-0(
          v-if="joinLeagueRadioGroup == 2",
          :style="{ height: $store.state.navbarHeight <= 48 ? $store.state.screenHeight - 280 + 'px' : 'auto' }"
        )
          league-card(
            v-for="(card, index) in joinLeagueData",
            :key="index",
            :leagueData="card",
            :allLeagues="false",
            @showBoxScoreDialog="showBoxScoreDialog",
            @showLeagueDetailDialog="showLeagueDetailDialog",
            @showLeagueRankingDialog="showLeagueRankingDialog"
          )
        .league_join.pb-12.pb-md-0(
          v-if="joinLeagueRadioGroup == 3",
          :style="{ height: $store.state.navbarHeight <= 48 ? $store.state.screenHeight - 280 + 'px' : 'auto' }"
        )
          league-card(
            v-for="(card, index) in joinLeagueData",
            :key="index",
            :leagueData="card",
            :allLeagues="false",
            @showBoxScoreDialog="showBoxScoreDialog",
            @showLeagueDetailDialog="showLeagueDetailDialog",
            @showLeagueRankingDialog="showLeagueRankingDialog"
          )
        v-pagination(
          v-model="joinLeagueCurrentPage",
          :length="joinLeagueTotalPages",
          prev-icon="mdi-menu-left",
          next-icon="mdi-menu-right",
          circle
        )
    transition(name="page", mode="out-in")
      .league_stats.wrapper.py-10.pa-0.py-md-5.px-md-9(v-if="tab == 2")
        .league_header_tools.d-flex.justify-space-between.align-center
          .available_league
            league-card(
              v-for="(card, index) in joinLeagueData",
              :key="index",
              :leagueData="card",
              :allLeagues="false",
              @showBoxScoreDialog="showBoxScoreDialog",
              :reward="true",
              @showGetCoinReceivingDialog="showGetCoinReceivingDialog",
              @showLeagueDetailDialog="showLeagueDetailDialog",
              @showLeagueRankingDialog="showLeagueRankingDialog"
            )
            .empty_alert.pb-5(v-if="isAvailableLeagueEmptyAlertShow")
              h5.text-center {{ availableLeagueEmptyAlertText }}
  .action.d-flex.align-center.justify-center.justify-md-end.pr-0.pr-md-5.d-md-none
    .btn.btn_water_blue.search_league_btn(@click="showLeagueSearchDialog") 搜尋聯賽
    .btn.btn_water_blue.create_league_btn(@click="showLeagueCreateDialog") 建立聯賽
  .action_cover(@click="isActionExpand = false", v-if="isActionExpand")
  .action_btns.d-block.d-md-none
    .action_wrapper(:class="{ expand: isActionExpand }")
      .action.local_btn(@click="showLeagueSearchDialog") 搜尋
        span 聯賽
      .action.auto_btn(@click="showLeagueCreateDialog") 建立
        span 聯賽
      .block
    .action_btn(@click="isActionExpand = !isActionExpand")
      font-awesome-icon.magic(
        v-if="isActionExpand",
        :icon="['fas', 'chevron-down']"
      )
      font-awesome-icon.magic(v-else="", :icon="['fas', 'magic']")

  dialog-league-detail(
    @closeLeagueDetailDialog="closeLeagueDetailDialog",
    @reloadLeague="reloadLeague",
    @reloadLeagueByCancel="reloadLeagueByCancel",
    :dialogLeagueDetail="dialogLeagueDetailShow",
    :leagueData="currentLeagueData",
    :needUpdate="needUpdate",
    :room_key="room_key"
  )
  dialog-league-ranking(
    @closeLeagueRankingDialog="closeLeagueRankingDialog",
    :dialogLeagueRanking="dialogLeagueRankingShow",
    :leagueData="currentLeagueData"
  )
  dialog-league-search(
    @closeLeagueSearchDialog="closeLeagueSearchDialog",
    :dialogLeagueSearch="dialogLeagueSearchShow",
    @showLeagueDetailDialog="showLeagueDetailDialog"
  )
  dialog-league-create(
    @closeLeagueCreateDialog="closeLeagueCreateDialog",
    @reloadLeagueByCancel="reloadLeagueByCancel",
    :dialogLeagueCreate="dialogLeagueCreateShow",
    :needUpdate="needUpdate"
  )
  dialog-private-key(
    @closePrivateKeyDialog="closePrivateKeyDialog",
    @sendLeaguePwd="sendLeaguePwd",
    :dialogPrivateKey="dialogPrivateKeyShow"
  )
  dialog-get-coin-success(
    @closeGetCoinSuccessDialog="closeGetCoinSuccessDialog",
    :dialogGetCoinSuccess="dialogGetCoinSuccessShow"
  )
  dialog-coin-receiving(:dialogCoinReceiving="dialogCoinReceivingShow")
  dialog-box-score(
    :boxScoreData="currentBoxScoreData",
    :dialogBoxScore="dialogBoxScoreShow",
    @closeBoxScoreDialog="closeBoxScoreDialog"
  )
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p 讀取中
.create.text-center(v-else-if="$store.state.user.accessToken && !isTeamExist")
  p(style="margin-top: 100px") 尚未建立球隊
  .btn.btn_water_blue.mt-6(@click="toCreateTeam") 創建球隊
.empty_cover.text-center(v-else-if="!$store.state.user.accessToken")
  img.d-none.d-sm-block(:src="blurCover")
  img.d-sm-none(:src="blurCoverM")
  .msg
    p(style="margin-top: 0px") 登入後加入聯賽
    .btn.btn_water_blue.mt-6(@click="toLogin") 前往登入
</template>

<script>
import gcAvatar from "@/assets/img/gc-avatar.png";
import blurCover from "@/assets/img/my_team/blur_cover.png";
import blurCoverM from "@/assets/img/my_team/blur_cover_m.png";
import LeagueCard from "@/components/fantasy/my_league/LeagueCard";
import DialogLeagueDetail from "@/components/fantasy/my_league/DialogLeagueDetail";
import DialogLeagueRanking from "@/components/fantasy/my_league/DialogLeagueRanking";
import DialogLeagueCreate from "@/components/fantasy/my_league/DialogLeagueCreate";
import DialogPrivateKey from "@/components/fantasy/my_league/DialogPrivateKey";
import DialogLeagueSearch from "@/components/fantasy/my_league/DialogLeagueSearch";
import DialogGetCoinSuccess from "@/components/fantasy/my_league/DialogGetCoinSuccess";
import DialogCoinReceiving from "@/components/fantasy/my_league/DialogCoinReceiving";
import DialogBoxScore from "@/components/fantasy/my_league/DialogBoxScore";
import DialogLoading from "@/components/dialog/DialogLoading";
import {
  checkMyTeam,
  getUserSeasonData,
  getFantasySeasonLeagueList,
  getFantasySeasonLeagueRetrieve,
} from "@/api/fantasy";
import { getUserInfo } from "@/api/user";

export default {
  name: "MyLeague",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  props: {
    needUpdate: Boolean,
  },
  components: {
    LeagueCard,
    DialogLeagueDetail,
    DialogLeagueRanking,
    DialogLeagueCreate,
    DialogPrivateKey,
    DialogLeagueSearch,
    DialogGetCoinSuccess,
    DialogCoinReceiving,
    DialogBoxScore,
    DialogLoading,
  },
  data() {
    return {
      dialogLoadingShow: true,
      userInfo: {},
      allLeagueRadioGroup: 0,
      joinLeagueRadioGroup: 0,
      tab: 0,

      allLeagueData: [],
      joinLeagueData: [],

      allLeagueCurrentPage: 1,
      allLeagueTotalPages: 1,
      joinLeagueCurrentPage: 1,
      joinLeagueTotalPages: 1,
      perPage: 10,

      typeList: ["官方", "玩家"],
      statusList: ["全部", "進行中", "未開始", "已結束"],
      isAllLeagueEmptyAlertShow: false,
      isJoinLeagueEmptyAlertShow: false,
      isAvailableLeagueEmptyAlertShow: false,
      allLeagueEmptyAlertText: "",
      joinLeagueEmptyAlertText: "",
      availableLeagueEmptyAlertText: "您沒有可領取的獎勵",
      sortIndex: 2,
      isSortAscending: true,
      text: "",
      timeout: 2000,
      gcAvatar,
      dialogLeagueDetailShow: false,
      dialogLeagueRankingShow: false,
      dialogLeagueCreateShow: false,
      dialogPrivateKeyShow: false,
      dialogLeagueSearchShow: false,
      dialogGetCoinSuccessShow: false,
      dialogCoinReceivingShow: false,
      dialogBoxScoreShow: false,
      currentLeagueData: null,
      currentLeagueId: null,
      currentLeagueType: null,
      currentLeaguePublic: null,
      currentLeagueCreator: null,
      currentBoxScoreData: null,
      seasonData: {},
      blurCover,
      blurCoverM,
      isTeamExist: null,
      myTeamInfo: {},
      room_key: null,

      isActionExpand: false,

      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "GC Fantasy 我的聯賽 | Game Changer",
        description: "GC Fantasy 我的聯賽",
        keywords: "Game changer,籃球,會員,GC Fantasy",
        url: "https://www.gamechanger.tw/fantasy/myteam",
        image: "",
        smoType: "website",
      },
    };
  },
  created() {
    this.getUserSeasonData();
    this.getUserInfo();
  },
  watch: {
    needUpdate() {
      this.getUserInfo();
    },
    allLeagueCurrentPage() {
      this.allLeagueData = [];
      if (this.allLeagueRadioGroup == 0) {
        if (this.sortIndex == 2) {
          this.getFantasySeasonLeagueList(0, "START_DATE");
        } else if (this.sortIndex == 1) {
          this.getFantasySeasonLeagueList(0, "ENTRY_FEE");
        } else if (this.sortIndex == 0) {
          this.getFantasySeasonLeagueList(0, "MAX_TEAM_COUNT");
        }
      } else if (this.allLeagueRadioGroup == 1) {
        if (this.sortIndex == 2) {
          this.getFantasySeasonLeagueList(1, "START_DATE");
        } else if (this.sortIndex == 1) {
          this.getFantasySeasonLeagueList(1, "ENTRY_FEE");
        } else if (this.sortIndex == 0) {
          this.getFantasySeasonLeagueList(1, "MAX_TEAM_COUNT");
        }
      }
    },
    joinLeagueCurrentPage() {
      this.joinLeagueData = [];
      if (this.joinLeagueRadioGroup == 0) {
        this.getJoinFantasySeasonLeagueList(0);
      }
      if (this.joinLeagueRadioGroup == 1) {
        this.getJoinFantasySeasonLeagueList(1);
      }
      if (this.joinLeagueRadioGroup == 2) {
        this.getJoinFantasySeasonLeagueList(2);
      }
      if (this.joinLeagueRadioGroup == 3) {
        this.getJoinFantasySeasonLeagueList(3);
      }
    },
    tab() {
      if (this.tab == 0) {
        this.allLeagueRadioGroup = 0;
        if (this.sortIndex == 2) {
          this.getFantasySeasonLeagueList(0, "START_DATE");
        } else if (this.sortIndex == 1) {
          this.getFantasySeasonLeagueList(0, "ENTRY_FEE");
        } else if (this.sortIndex == 0) {
          this.getFantasySeasonLeagueList(0, "MAX_TEAM_COUNT");
        }
      }
      if (this.tab == 1) {
        this.joinLeagueRadioGroup = 0;
        this.getJoinFantasySeasonLeagueList(0);
      }
      if (this.tab == 2) {
        this.getJoinFantasySeasonLeagueList(4);
      }
    },
    allLeagueRadioGroup() {
      this.allLeagueData = [];
      this.allLeagueCurrentPage = 1;
      this.allLeagueTotalPages = 1;
      if (this.allLeagueRadioGroup == 0) {
        if (this.sortIndex == 2) {
          this.getFantasySeasonLeagueList(0, "START_DATE");
        } else if (this.sortIndex == 1) {
          this.getFantasySeasonLeagueList(0, "ENTRY_FEE");
        } else if (this.sortIndex == 0) {
          this.getFantasySeasonLeagueList(0, "MAX_TEAM_COUNT");
        }
      } else if (this.allLeagueRadioGroup == 1) {
        if (this.sortIndex == 2) {
          this.getFantasySeasonLeagueList(1, "START_DATE");
        } else if (this.sortIndex == 1) {
          this.getFantasySeasonLeagueList(1, "ENTRY_FEE");
        } else if (this.sortIndex == 0) {
          this.getFantasySeasonLeagueList(1, "MAX_TEAM_COUNT");
        }
      }
    },
    joinLeagueRadioGroup() {
      this.joinLeagueCurrentPage = 1;
      this.joinLeagueTotalPages = 1;
      this.joinLeagueData = [];
      if (this.joinLeagueRadioGroup == 0) {
        this.getJoinFantasySeasonLeagueList(0);
      }
      if (this.joinLeagueRadioGroup == 1) {
        this.getJoinFantasySeasonLeagueList(1);
      }
      if (this.joinLeagueRadioGroup == 2) {
        this.getJoinFantasySeasonLeagueList(2);
      }
      if (this.joinLeagueRadioGroup == 3) {
        this.getJoinFantasySeasonLeagueList(3);
      }
    },
    allLeagueData() {
      if (this.allLeagueData.length == 0) {
        this.isAllLeagueEmptyAlertShow = true;
        this.allLeagueEmptyAlertText = "目前沒有可參加的聯賽喔！";
      } else {
        this.isAllLeagueEmptyAlertShow = false;
        this.allLeagueEmptyAlertText = "";
      }
    },
    joinLeagueData() {
      if (this.joinLeagueData.length == 0) {
        if (this.tab == 1) {
          this.isJoinLeagueEmptyAlertShow = true;

          switch (this.joinLeagueRadioGroup) {
            case 0:
              this.joinLeagueEmptyAlertText = "您沒有參加的聯賽";
              break;
            case 1:
              this.joinLeagueEmptyAlertText = "您沒有進行中的聯賽";
              break;
            case 2:
              this.joinLeagueEmptyAlertText = "您沒有未開始的聯賽";
              break;
            case 3:
              this.joinLeagueEmptyAlertText = "您沒有已結束的聯賽";
              break;
            default:
              break;
          }
        } else {
          this.isAvailableLeagueEmptyAlertShow = true;
        }
      } else {
        this.isJoinLeagueEmptyAlertShow = false;
        this.joinLeagueEmptyAlertText = "";
      }
    },
  },
  methods: {
    getUserInfo() {
      getUserInfo().then((response) => {
        this.userInfo = response.data;
      });
    },
    async getUserSeasonData() {
      this.dialogLoadingShow = true;
      let temp = {
        state: "IN_SEASON",
      };
      await getUserSeasonData(temp).then((response) => {
        this.seasonData = response.data[0];

        if (this.seasonData != undefined) {
          this.checkMyTeam();
        }
      });
      this.dialogLoadingShow = false;
    },
    checkMyTeam() {
      if (this.$store.state.user.accessToken) {
        let temp = {
          season_id: this.seasonData.id,
        };
        checkMyTeam(temp).then((response) => {
          this.isTeamExist = response.data.has_team;
          this.myTeamInfo = response.data.team;

          if (this.isTeamExist) {
            if (this.sortIndex == 2) {
              this.getFantasySeasonLeagueList(0, "START_DATE");
            } else if (this.sortIndex == 1) {
              this.getFantasySeasonLeagueList(0, "ENTRY_FEE");
            } else if (this.sortIndex == 0) {
              this.getFantasySeasonLeagueList(0, "MAX_TEAM_COUNT");
            }
          }
        });
      }
    },
    async getFantasySeasonLeagueList(isOfficial, sort) {
      this.dialogLoadingShow = true;

      let temp;
      if (isOfficial == 0) {
        temp = {
          season_id: this.seasonData.id,
          state: "NEW",
          page: this.allLeagueCurrentPage,
          page_size: this.perPage,
          order: sort,
          is_official: true,
        };
      } else if (isOfficial == 1) {
        temp = {
          season_id: this.seasonData.id,
          state: "NEW",
          page: this.allLeagueCurrentPage,
          page_size: this.perPage,
          order: sort,
          is_official: false,
        };
      }

      await getFantasySeasonLeagueList(temp).then((response) => {
        this.getUserInfo();
        let res = response.data;
        this.allLeagueData = res.data;
        this.allLeagueTotalPages = res.total_page;
      });
      this.dialogLoadingShow = false;
    },
    async getJoinFantasySeasonLeagueList(status) {
      this.dialogLoadingShow = true;

      let temp;
      if (status == 0) {
        temp = {
          season_id: this.seasonData.id,
          is_joined: true,
          page: this.joinLeagueCurrentPage,
          page_size: this.perPage,
          order: "START_DATE",
        };
      } else if (status == 1) {
        temp = {
          season_id: this.seasonData.id,
          is_joined: true,
          page: this.joinLeagueCurrentPage,
          page_size: this.perPage,
          state: "IN_SEASON",
        };
      } else if (status == 2) {
        temp = {
          season_id: this.seasonData.id,
          is_joined: true,
          page: this.joinLeagueCurrentPage,
          page_size: this.perPage,
          state: "NEW",
        };
      } else if (status == 3) {
        temp = {
          season_id: this.seasonData.id,
          is_joined: true,
          page: this.joinLeagueCurrentPage,
          page_size: this.perPage,
          state: "END",
        };
      } else if (status == 4) {
        temp = {
          season_id: this.seasonData.id,
          is_joined: true,
          page: this.joinLeagueCurrentPage,
          page_size: this.perPage,
          state: "REWARD",
        };
      }
      await getFantasySeasonLeagueList(temp).then((response) => {
        this.getUserInfo();
        let res = response.data;
        this.joinLeagueData = res.data;
        this.joinLeagueTotalPages = res.total_page;
      });

      this.dialogLoadingShow = false;
    },
    async getFantasySeasonLeagueRetrieve() {
      this.dialogLoadingShow = true;

      let temp = {
        season_id: this.seasonData.id,
        league_id: this.currentLeagueId,
      };

      let data = {
        room_key: this.room_key,
      };
      await getFantasySeasonLeagueRetrieve(temp, data)
        .then((response) => {
          this.currentLeagueData = response.data;
          this.dialogLeagueDetailShow = true;
        })
        .catch(() => {
          this.room_key = undefined;
          this.dialogLoadingShow = false;
        });
      this.dialogLoadingShow = false;
    },
    toCreateTeam() {
      this.$emit("showNormalDialog");
      this.$router.push({ name: "CreateTeam" });
    },
    toLogin() {
      this.$router.push({ name: "Login" });
    },
    showBoxScoreDialog(data) {
      this.currentBoxScoreData = data;
      this.dialogBoxScoreShow = true;
    },
    closeBoxScoreDialog() {
      this.dialogBoxScoreShow = false;
    },
    clickSort(tabInedx) {
      if (this.sortIndex != tabInedx) {
        this.sortIndex = tabInedx;
        this.isSortAscending = true;

        if (this.allLeagueRadioGroup == 0) {
          if (this.sortIndex == 2) {
            this.getFantasySeasonLeagueList(0, "START_DATE");
          } else if (this.sortIndex == 1) {
            this.getFantasySeasonLeagueList(0, "ENTRY_FEE");
          } else if (this.sortIndex == 0) {
            this.getFantasySeasonLeagueList(0, "MAX_TEAM_COUNT");
          }
        } else if (this.allLeagueRadioGroup == 1) {
          if (this.sortIndex == 2) {
            this.getFantasySeasonLeagueList(1, "START_DATE");
          } else if (this.sortIndex == 1) {
            this.getFantasySeasonLeagueList(1, "ENTRY_FEE");
          } else if (this.sortIndex == 0) {
            this.getFantasySeasonLeagueList(1, "MAX_TEAM_COUNT");
          }
        }
      }
    },
    reloadLeague() {
      this.getFantasySeasonLeagueRetrieve();
      this.allLeagueData = [];
      if (this.allLeagueRadioGroup == 0) {
        if (this.sortIndex == 2) {
          this.getFantasySeasonLeagueList(0, "START_DATE");
        } else if (this.sortIndex == 1) {
          this.getFantasySeasonLeagueList(0, "ENTRY_FEE");
        } else if (this.sortIndex == 0) {
          this.getFantasySeasonLeagueList(0, "MAX_TEAM_COUNT");
        }
      } else if (this.allLeagueRadioGroup == 1) {
        if (this.sortIndex == 2) {
          this.getFantasySeasonLeagueList(1, "START_DATE");
        } else if (this.sortIndex == 1) {
          this.getFantasySeasonLeagueList(1, "ENTRY_FEE");
        } else if (this.sortIndex == 0) {
          this.getFantasySeasonLeagueList(1, "MAX_TEAM_COUNT");
        }
      }
      this.joinLeagueData = [];
      if (this.joinLeagueRadioGroup == 0) {
        this.getJoinFantasySeasonLeagueList(0);
      }
      if (this.joinLeagueRadioGroup == 1) {
        this.getJoinFantasySeasonLeagueList(1);
      }
      if (this.joinLeagueRadioGroup == 2) {
        this.getJoinFantasySeasonLeagueList(2);
      }
      if (this.joinLeagueRadioGroup == 3) {
        this.getJoinFantasySeasonLeagueList(3);
      }
      if (this.tab == 2) {
        this.getJoinFantasySeasonLeagueList(4);
      }
    },
    reloadLeagueByCancel() {
      this.dialogLeagueDetailShow = false;
      this.allLeagueData = [];
      if (this.allLeagueRadioGroup == 0) {
        if (this.sortIndex == 2) {
          this.getFantasySeasonLeagueList(0, "START_DATE");
        } else if (this.sortIndex == 1) {
          this.getFantasySeasonLeagueList(0, "ENTRY_FEE");
        } else if (this.sortIndex == 0) {
          this.getFantasySeasonLeagueList(0, "MAX_TEAM_COUNT");
        }
      } else if (this.allLeagueRadioGroup == 1) {
        if (this.sortIndex == 2) {
          this.getFantasySeasonLeagueList(1, "START_DATE");
        } else if (this.sortIndex == 1) {
          this.getFantasySeasonLeagueList(1, "ENTRY_FEE");
        } else if (this.sortIndex == 0) {
          this.getFantasySeasonLeagueList(1, "MAX_TEAM_COUNT");
        }
      }
      this.joinLeagueData = [];
      if (this.joinLeagueRadioGroup == 0) {
        this.getJoinFantasySeasonLeagueList(0);
      }
      if (this.joinLeagueRadioGroup == 1) {
        this.getJoinFantasySeasonLeagueList(1);
      }
      if (this.joinLeagueRadioGroup == 2) {
        this.getJoinFantasySeasonLeagueList(2);
      }
      if (this.joinLeagueRadioGroup == 3) {
        this.getJoinFantasySeasonLeagueList(3);
      }
    },
    async showLeagueDetailDialog(leagueData, id) {
      this.currentLeagueId = id;
      this.currentLeagueData = {};
      console.log("leagueData=", leagueData);
      console.log("userInfo=", this.userInfo);

      if (
        leagueData.is_private &&
        !leagueData.is_owner &&
        !leagueData.is_join
      ) {
        this.showPrivateKeyDialog();
      } else {
        await this.getFantasySeasonLeagueRetrieve();
      }
    },
    showPrivateKeyDialog() {
      this.room_key = null;
      this.dialogPrivateKeyShow = true;
    },
    closePrivateKeyDialog() {
      this.dialogPrivateKeyShow = false;
    },
    async sendLeaguePwd(room_key) {
      this.room_key = room_key;
      this.closePrivateKeyDialog();
      await this.getFantasySeasonLeagueRetrieve();
    },
    closeLeagueDetailDialog() {
      this.dialogLeagueDetailShow = false;
    },
    showLeagueRankingDialog(leagueData, id) {
      this.currentLeagueData = leagueData;
      this.currentLeagueId = id;
      this.dialogLeagueRankingShow = true;
    },
    closeLeagueRankingDialog() {
      this.dialogLeagueRankingShow = false;
    },
    showLeagueCreateDialog() {
      if (this.isActionExpand) {
        this.isActionExpand = false;
      }
      this.dialogLeagueCreateShow = true;
    },
    closeLeagueCreateDialog() {
      this.dialogLeagueCreateShow = false;
    },
    showLeagueSearchDialog() {
      if (this.isActionExpand) {
        this.isActionExpand = false;
      }
      this.dialogLeagueSearchShow = true;
    },
    closeLeagueSearchDialog() {
      this.dialogLeagueSearchShow = false;
    },
    showGetCoinSuccessDialog() {
      this.dialogGetCoinSuccessShow = true;
    },
    closeGetCoinSuccessDialog() {
      this.dialogGetCoinSuccessShow = false;
    },
    showGetCoinReceivingDialog(id) {
      console.log(id); //獎勵之房間
      this.dialogCoinReceivingShow = true;

      var self = this;
      setTimeout(() => {
        self.dialogCoinReceivingShow = false;
        self.showGetCoinSuccessDialog();
      }, 1000);
    },
    showAchievementDialog() {
      this.$emit("showAchievementDialog");
    },
    toMemberCenter() {
      this.$router.push({
        name: "MemberCenter",
        params: {
          tab: 1,
        },
      });
    },
    toThousands(num) {
      var temp = num + "";
      var result = "";
      while (temp.length > 3) {
        result = "," + temp.slice(-3) + result;
        temp = temp.slice(0, temp.length - 3);
      }
      if (temp) {
        result = temp + result;
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/pagination";
@import "@/assets/style/fantasy/my_team_league";

::v-deep .v-input--selection-controls__input {
  .v-icon.v-icon {
    font-size: 18px;
  }
}

.league_stats {
  box-shadow: 0 0 30px 0 #e1e8f0;
  width: 100%;
  overflow: hidden;
}

.league_stats_header {
  width: 100%;
  height: 64px;
  background: #f7f7f7;
}

.league_states_menu {
  width: 50%;
  height: 100%;
  padding-left: 12px;

  .v-tab {
    color: #68747f !important;
    font-weight: normal;
    font-size: 14px;
  }

  .v-tab:not(:last-child) {
    border-right: 1px solid #dddddd;
  }

  .v-tab--active {
    color: #1f1f1f !important;
    font-weight: bold;
  }
}

.action {
  width: 50%;
  height: 100%;
}

input.search {
  width: 66%;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  background: url("~@/assets/img/search.png") no-repeat right center;
  background-position: calc(100% - 10px) center;
  border-bottom: 1px solid #8eb0d6;
  font-size: 16px;
  padding: 8px 12px;
}

.create_league_btn {
  margin-left: 3%;
  width: 31%;
}

.league_stats_body {
  width: 100%;
  background: white;
}

.sort_btn {
  font-size: 14px;
  color: #68747f;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;

  svg {
    display: none;
  }
}

.sort_btn.active {
  color: #51769d;
  font-weight: bold;
  svg {
    display: inline-block;
  }
  svg.rotate {
    transform: rotate(180deg);
  }
}

@media (max-width: 959px) {
  // .league_stats_header {
  //   position: absolute;
  //   top: 0;
  // }
  .my_league {
    position: relative;
  }
  // .league_stats.wrapper {
  //   position: absolute;
  //   top: 0;
  // }

  .league_stats.radius_l {
    position: fixed !important;
  }

  .league_states_tabs {
    width: 100%;
  }

  .league_states_menu {
    width: 100%;
    height: 100%;
    background: #f7f7f7;
    padding-left: 0px;

    ::v-deep .v-tab {
      color: #a8a8a8 !important;
      font-weight: normal;
      font-size: 12px;
      height: 30px;
      padding: 0;
      flex: 1 1 auto;
      white-space: nowrap;
    }

    ::v-deep
      .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
      > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
      .v-slide-group__prev {
      display: none;
    }

    .v-tab:not(:last-child) {
      border-right: 1px solid rgba(white, 0.5);
    }

    .v-tab--active {
      color: #6c7a8e !important;
      font-weight: bold;
      border-bottom: #6c7a8e 2px solid;
    }

    ::v-deep .v-tabs-bar__content {
      justify-content: center;
    }

    ::v-deep .theme--light.v-tabs > .v-tabs-bar {
      height: 30px;
    }
  }

  .league_stats {
    border-radius: 0 !important;
    box-shadow: none;
  }

  .league_stats_header {
    height: auto;
  }

  .action {
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }

  .search_league_btn {
    margin-left: 0;
    flex-grow: 1;
  }

  .create_league_btn {
    flex-grow: 1;
  }

  .league_header_tools {
    padding-left: 8px;
    padding-right: 8px;
  }

  .action_btns {
    position: fixed;
    bottom: 150px;
    right: 5px;
    width: 50px;
    height: 240px;
    cursor: pointer;
    z-index: 200;

    .action_btn {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 50px;
      height: 50px;
      background-color: #8eb0d6;
      color: white;
      border-radius: 50%;
      box-shadow: 0 2px 4px rgba(#3b3d40, 0.2);
    }

    .magic {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
      height: 24px;
    }

    .action_wrapper {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: auto;
      right: 0;
      border-radius: 25px;
      background: #f4f7fb;
      max-height: 0px;
      transition: all 0.5s;
      overflow: hidden;

      .action {
        text-align: center;
        width: 50px;
        margin: auto;
        display: block;
        padding-top: 10px;
        padding-bottom: 10px;
        cursor: pointer;

        span {
          display: block;
        }
      }

      .action:not(:last-child) {
        font-size: 12px;
        border-bottom: white 1px solid;
        font-weight: bold;
      }
      .foreign_btn {
        color: #d88e8f;
      }
      .local_btn {
        color: #8e93d6;
      }
      .auto_btn {
        color: #537fad;
      }
    }

    .action_wrapper.expand {
      max-height: 240px;
    }

    .block {
      height: 50px;
    }
  }
}

.league_all,
.league_join {
  overflow: auto;
}

.available_league {
  width: 100%;
  height: auto;
}

@media (max-width: 599px) {
  ::v-deep .v-label {
    font-size: 14px !important;
  }

  ::v-deep .v-input--selection-controls__input {
    display: none;
  }

  ::v-deep
    .v-input--radio-group.v-input--radio-group--row
    .v-radio.v-item--active {
    font-weight: bold;

    .theme--light.v-label {
      color: #002442;
    }
  }
}
</style>
