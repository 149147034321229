<template lang="pug">
v-dialog(v-model="dialogWarning", max-width="290", persistent)
  v-card
    v-card-title.flex-column.justify-center
      .text-h5 {{ title }}
      font-awesome-icon.info_icon.mt-6(
        color="#F38E8E",
        :icon="['fas', 'info-circle']"
      )
    v-card-text.text-center.mt-3
      slot(name="content")
    v-card-actions
      v-spacer
      v-btn(color="darken-1", text="", @click="closeDialog") 取消
      v-btn(color="darken-1", v-show="actionName != '錯誤'", text="", @click="action") {{ actionName }}
</template>

<script>
export default {
  name: "DialogWarning",
  props: {
    title: String,
    dialogWarning: Boolean,
    actionName: String,
    actionFlag: Number,
  },
  methods: {
    closeDialog() {
      this.$emit("closeWarningDialog");
    },
    action() {
      this.$emit("action", this.actionFlag);
    },
  },
};
</script>

<style lang="scss" scoped>
svg.info_icon {
  width: 40px;
  height: 40px;
}

::v-deep .v-dialog {
  width: 100%;
}
</style>
