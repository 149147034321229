<template lang="pug">
v-dialog.mb-15(
  v-model="dialogLeagueRanking",
  :scrollable="true",
  max-width="100%",
  persistent
)
  v-card(v-if="leagueData != null")
    v-container.container--fluid.card_header(
      :class="{ gc: leagueData.type == 'gc' }"
    )
      v-card-title.flex-column.justify-center.pa-0
        .league_title.d-flex.align-center
          svg-icon.league_icon(
            v-if="leagueData.type == 'gc'",
            iconClass="gc_logo"
          )
          .league_name.ml-1 {{ leagueData.leagueName }}
          font-awesome-icon.league_private.ml-2(
            v-if="leagueData.public",
            :icon="['fas', 'globe-americas']"
          )
          font-awesome-icon.league_private.ml-2(
            v-if="!leagueData.public",
            :icon="['fas', 'key']"
          )

      v-container.ranking_container.container--fluid.px-0.px-md-7.pt-0.pb-10.mt-12
        .d-flex.justify-center
          h2.text-center.text_f_primary {{ getRankTitle() + '排行榜' }}
        v-row
          v-col
            h3 玩家排行
        v-row.table_row.mt-0
          v-col.table.f_table.table_col
            table
              thead
                tr.bottom_head
                  th.text-left 名字
                  th(
                    style="cursor: pointer",
                    :class="{ sort_up: sortValue.type == 'ast' && sortValue.order == 'ASC', sort_down: sortValue.type == 'ast' && sortValue.order == 'DESC' }"
                  ) 
                    span 助攻
                    font-awesome-icon.ml-2(:icon="getTableSort('ast')") 
                  th(
                    style="cursor: pointer",
                    :class="{ sort_up: sortValue.type == 'reb' && sortValue.order == 'ASC', sort_down: sortValue.type == 'reb' && sortValue.order == 'DESC' }"
                  ) 
                    span 籃板
                    font-awesome-icon.ml-2(:icon="getTableSort('reb')") 
                  th(
                    style="cursor: pointer",
                    :class="{ sort_up: sortValue.type == 'stl' && sortValue.order == 'ASC', sort_down: sortValue.type == 'stl' && sortValue.order == 'DESC' }"
                  ) 
                    span 抄截
                    font-awesome-icon.ml-2(:icon="getTableSort('stl')") 
                  th(
                    style="cursor: pointer",
                    :class="{ sort_up: sortValue.type == 'blk' && sortValue.order == 'ASC', sort_down: sortValue.type == 'blk' && sortValue.order == 'DESC' }"
                  ) 
                    span 火鍋
                    font-awesome-icon.ml-2(:icon="getTableSort('blk')")
                  th(
                    style="cursor: pointer",
                    :class="{ sort_up: sortValue.type == 'pts' && sortValue.order == 'ASC', sort_down: sortValue.type == 'pts' && sortValue.order == 'DESC' }"
                  ) 
                    span 得分
                    font-awesome-icon.ml-2(:icon="getTableSort('pts')")
                  th(
                    style="cursor: pointer",
                    :class="{ sort_up: sortValue.type == 'score' && sortValue.order == 'ASC', sort_down: sortValue.type == 'score' && sortValue.order == 'DESC' }"
                  ) 
                    span 積分
                    font-awesome-icon.ml-2(:icon="getTableSort('score')")
              tbody
                tr(v-if="currentList.length == 0")
                  td.bdl(colspan="8") 尚無資料
                tr(
                  v-if="currentList.length > 0",
                  v-for="(data, index) in currentList",
                  :key="index",
                  style="cursor: pointer",
                  :class="getSwitchRankPlayer(index)",
                  @click="setSwitchRankPlayer(index)"
                )
                  td
                    .player_container.d-flex.align-center
                      img.avatar(
                        v-if="data.user && data.user.photo && data.user.photo != ''",
                        :src="data.user.photo"
                      )
                      img.avatar(
                        v-if="!data.user || !data.user.photo || data.user.photo == ''",
                        :src="gcAvatar"
                      )
                      span.ml-1 {{ data.user.nickname }}
                  td 
                    span.value {{ data.box.ast }}
                    span.rank(:class="getRankStyle(data.ranking_ast)") {{ getRankText(data.ranking_ast) }}
                      span.rank_num(
                        :class="getRankRise(data.last_ranking_ast, data.ranking_ast)"
                      ) {{ Math.abs(data.last_ranking_ast - data.ranking_ast) }}
                      svg-icon(
                        :iconClass="getRankRise(data.last_ranking_ast, data.ranking_ast)",
                        className="rank_icon"
                      )
                  td 
                    span.value {{ data.box.reb }}
                    span.rank(:class="getRankStyle(data.ranking_reb)") {{ getRankText(data.ranking_reb) }}
                      span.rank_num(
                        :class="getRankRise(data.last_ranking_reb, data.ranking_reb)"
                      ) {{ Math.abs(data.last_ranking_reb - data.ranking_reb) }}
                      svg-icon(
                        :iconClass="getRankRise(data.last_ranking_reb, data.ranking_reb)",
                        className="rank_icon"
                      )
                  td 
                    span.value {{ data.box.stl }}
                    span.rank(:class="getRankStyle(data.ranking_stl)") {{ getRankText(data.ranking_stl) }}
                      span.rank_num(
                        :class="getRankRise(data.last_ranking_stl, data.ranking_stl)"
                      ) {{ Math.abs(data.last_ranking_stl - data.ranking_stl) }}
                      svg-icon(
                        :iconClass="getRankRise(data.last_ranking_stl, data.ranking_stl)",
                        className="rank_icon"
                      )
                  td 
                    span.value {{ data.box.blk }}
                    span.rank(:class="getRankStyle(data.ranking_blk)") {{ getRankText(data.ranking_blk) }}
                      span.rank_num(
                        :class="getRankRise(data.last_ranking_blk, data.ranking_blk)"
                      ) {{ Math.abs(data.last_ranking_blk - data.ranking_blk) }}
                      svg-icon(
                        :iconClass="getRankRise(data.last_ranking_blk, data.ranking_blk)",
                        className="rank_icon"
                      )
                  td 
                    span.value {{ data.box.pts }}
                    span.rank(:class="getRankStyle(data.ranking_pts)") {{ getRankText(data.ranking_pts) }}
                      span.rank_num(
                        :class="getRankRise(data.last_ranking_pts, data.ranking_pts)"
                      ) {{ Math.abs(data.last_ranking_pts - data.ranking_pts) }}
                      svg-icon(
                        :iconClass="getRankRise(data.last_ranking_pts, data.ranking_pts)",
                        className="rank_icon"
                      )
                  td
                    span.value {{ data.score }}
                    span.rank(:class="getRankStyle(data.ranking)") {{ getRankText(data.ranking) }}
                      span.rank_num(
                        :class="getRankRise(data.last_ranking, data.ranking)"
                      ) {{ Math.abs(data.last_ranking - data.ranking) }}
                      svg-icon(
                        :iconClass="getRankRise(data.last_ranking, data.ranking)",
                        className="rank_icon"
                      )
        v-row
          v-col
            v-pagination(
              v-model="currentPage",
              :length="totalPages",
              prev-icon="mdi-menu-left",
              next-icon="mdi-menu-right",
              circle
            )
        v-row(v-if="$store.state.user.accessToken")
          v-col
            h3.text_f_blue 個人成績
        v-row.table_row.mt-0(v-if="$store.state.user.accessToken")
          v-col.table.f_table.self.table_col
            table
              thead
                tr.bottom_head
                  th.text-left 名字
                  th 助攻
                  th 籃板
                  th 抄截
                  th 火鍋
                  th 得分
                  th 積分
              tbody
                tr(v-if="!personalData.user") 
                  td.bdl(colspan="8") 尚無資料
                tr(
                  v-if="personalData.user",
                  style="cursor: pointer",
                  :class="{ switch_rank: switchRankPersonal }",
                  @click="switchRankPersonal = !switchRankPersonal"
                )
                  td
                    .player_container.d-flex.align-center
                      img.avatar(
                        v-if="personalData.user && personalData.user.photo && personalData.user.photo != ''",
                        :src="personalData.user.photo"
                      )
                      img.avatar(
                        v-if="!personalData.user || !personalData.user.photo || personalData.user.photo == ''",
                        :src="gcAvatar"
                      )
                      span.ml-1(v-if="personalData.user") {{ personalData.user.nickname }}
                  td(v-if="personalData.box") 
                    span.value {{ personalData.box.ast }}
                    span.rank(:class="getRankStyle(personalData.ranking_ast)") {{ getRankText(personalData.ranking_ast) }}
                      span.rank_num(
                        :class="getRankRise(personalData.last_ranking_ast, personalData.ranking_ast)"
                      ) {{ Math.abs(personalData.last_ranking_ast - personalData.ranking_ast) }}
                      svg-icon(
                        :iconClass="getRankRise(personalData.last_ranking_ast, personalData.ranking_ast)",
                        className="rank_icon"
                      )
                  td(v-if="personalData.box") 
                    span.value {{ personalData.box.reb }}
                    span.rank(:class="getRankStyle(personalData.ranking_reb)") {{ getRankText(personalData.ranking_reb) }}
                      span.rank_num(
                        :class="getRankRise(personalData.last_ranking_reb, personalData.ranking_reb)"
                      ) {{ Math.abs(personalData.last_ranking_reb - personalData.ranking_reb) }}
                      svg-icon(
                        :iconClass="getRankRise(personalData.last_ranking_reb, personalData.ranking_reb)",
                        className="rank_icon"
                      )
                  td(v-if="personalData.box") 
                    span.value {{ personalData.box.stl }}
                    span.rank(:class="getRankStyle(personalData.ranking_stl)") {{ getRankText(personalData.ranking_stl) }}
                      span.rank_num(
                        :class="getRankRise(personalData.last_ranking_stl, personalData.ranking_stl)"
                      ) {{ Math.abs(personalData.last_ranking_stl - personalData.ranking_stl) }}
                      svg-icon(
                        :iconClass="getRankRise(personalData.last_ranking_stl, personalData.ranking_stl)",
                        className="rank_icon"
                      )
                  td(v-if="personalData.box") 
                    span.value {{ personalData.box.blk }}
                    span.rank(:class="getRankStyle(personalData.ranking_blk)") {{ getRankText(personalData.ranking_blk) }}
                      span.rank_num(
                        :class="getRankRise(personalData.last_ranking_blk, personalData.ranking_blk)"
                      ) {{ Math.abs(personalData.last_ranking_blk - personalData.ranking_blk) }}
                      svg-icon(
                        :iconClass="getRankRise(personalData.last_ranking_blk, personalData.ranking_blk)",
                        className="rank_icon"
                      ) {{ personalData.box.blk }}
                  td(v-if="personalData.box") 
                    span.value {{ personalData.box.pts }}
                    span.rank(:class="getRankStyle(personalData.ranking_pts)") {{ getRankText(personalData.ranking_pts) }}
                      span.rank_num(
                        :class="getRankRise(personalData.last_ranking_pts, personalData.ranking_pts)"
                      ) {{ Math.abs(personalData.last_ranking_pts - personalData.ranking_pts) }}
                      svg-icon(
                        :iconClass="getRankRise(personalData.last_ranking_pts, personalData.ranking_pts)",
                        className="rank_icon"
                      )
                  td(v-if="personalData")
                    span.value {{ personalData.score }}
                    span.rank(:class="getRankStyle(personalData.ranking)") {{ getRankText(personalData.ranking) }}
                      span.rank_num(
                        :class="getRankRise(personalData.last_ranking, personalData.ranking)"
                      ) {{ Math.abs(personalData.last_ranking - personalData.ranking) }}
                      svg-icon(
                        :iconClass="getRankRise(personalData.last_ranking, personalData.ranking)",
                        className="rank_icon"
                      )

    v-card-actions.justify-center
      v-btn(small, outlined, color="dark-1", text="", @click="closeDialog") close
</template>

<script>
import gcAvatar from "@/assets/img/gc-avatar.png";
import { getSeasonData, getFantasySeasonLeagueRanking } from "@/api/fantasy";

export default {
  name: "DialogLeagueRanking",
  props: {
    title: String,
    dialogLeagueRanking: Boolean,
    icon: String,
    leagueData: Object,
  },
  async created() {
    await this.getSeasonData();
  },
  watch: {
    currentPage() {
      this.getSeasonLeagueRanking();
    },
    dialogLeagueRanking() {
      if (this.leagueData) {
        this.getSeasonLeagueRanking();
      }
    },
  },
  data() {
    return {
      seasonData: {},
      overviewData: {},
      seasonLeagueRanking: {},

      mainSelect: "ALL",
      subSelect: {},
      subSelectList: [],

      allList: [],
      currentList: [],
      personalData: {},
      currentPage: 1,
      totalPages: 1,
      perPage: 10,

      sortValue: {
        type: "",
        order: "DESC",
      },

      userMagic: "",

      dialogSearch: false,
      dialogOtherTeam: false,
      gcAvatar,
      switchRankPlayer: -1,
      switchRankPersonal: false,

      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "GC Fantasy 排名 | Game Changer",
        description: "GC Fantasy 排名",
        keywords: "Game changer,籃球,會員,GC Fantasy",
        url: "https://www.gamechanger.tw/fantasy/overview",
        image: "",
        smoType: "website",
      },
    };
  },
  methods: {
    async getSeasonData() {
      let temp = {
        state: "IN_SEASON",
      };
      await getSeasonData(temp).then((response) => {
        if (response.data.length > 0) {
          this.seasonData = response.data[0];
        }
      });
    },
    getSeasonLeagueRanking() {
      let temp = {
        season_id: this.seasonData.id,
        league_id: this.leagueData.id,
        page: this.currentPage,
        page_size: this.perPage,
      };
      getFantasySeasonLeagueRanking(temp).then((response) => {
        this.currentList = response.data.data;
        this.totalPages = response.data.total_page;
        this.personalData = response.data.my_team;
        this.sortValue.type = "score";
      });
    },
    getTableSort(type) {
      if (type == this.sortValue.type) {
        if ("ASC" == this.sortValue.order) {
          return ["fas", "caret-up"];
        } else {
          return ["fas", "caret-down"];
        }
      } else {
        return ["fas", "caret-down"];
      }
    },
    getRankTitle() {
      switch (this.sortValue.type) {
        case "pts":
          return "得分";
        case "blk":
          return "火鍋";
        case "stl":
          return "抄截";
        case "reb":
          return "籃板";
        case "ast":
          return "助攻";
        case "score":
          return "積分";
        default:
          return "";
      }
    },
    getRankStyle(rank) {
      if (rank == 1) {
        return "gold";
      } else if (rank == 2) {
        return "sliver";
      } else if (rank == 3) {
        return "copper";
      } else {
        return "";
      }
    },
    getRankText(rank) {
      switch (rank) {
        case 1:
          return "1st";
        case 2:
          return "2nd";
        case 3:
          return "3rd";
        default:
          return rank + "th";
      }
    },
    getRankRise(last, current) {
      if (last == 0) {
        return "rank_flat";
      } else if (last - current < 0) {
        return "rank_down";
      } else if (last - current > 0) {
        return "rank_up";
      } else {
        return "rank_flat";
      }
    },
    getSwitchRankPlayer(index) {
      if (this.switchRankPlayer == index) {
        return "switch_rank";
      } else {
        return "";
      }
    },
    setSwitchRankPlayer(index) {
      if (this.switchRankPlayer == index) {
        this.switchRankPlayer = -1;
      } else {
        this.switchRankPlayer = index;
      }
    },
    closeDialog() {
      this.$emit("closeLeagueRankingDialog");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/pagination";
@import "@/assets/style/fantasy/overview";

::v-deep .v-dialog {
  width: 100%;
}
</style>
