<template lang="pug">
v-dialog(v-model="dialogGetCoinSuccess", max-width="450", persistent)
  v-card.pb-4
    v-card-title.flex-column.justify-center.pb-0
      .text-h6 領取成功！
      //- svg-icon.mb-6(iconClass="receiving_icon", className="receiving_icon")
    v-card-text.pa-3
      .received_content.pb-4
        .detail.d-flex.center
          .coin_block.flex-column.d-flex.align-center.flex-grow-1
            svg-icon(iconClass="gc_coin_icon", className="icon")
            span.text_deep_black.mb-3 Fantasy幣
            span +100
    v-card-actions.justify-center
      v-btn(small, outlined, color="dark-1", text="", @click="closeDialog") close
</template>

<script>
export default {
  name: "DialogGetCoinSuccess",
  props: {
    dialogGetCoinSuccess: Boolean,
  },
  methods: {
    closeDialog() {
      this.$emit("closeGetCoinSuccessDialog");
    },
  },
};
</script>

<style scoped>
::v-deep .v-dialog {
  background-image: linear-gradient(270deg, #e2ddd4 0%, #f4d9a2 100%);
  background-position: 95% -5%;
  width: 100%;
}

::v-deep .v-card {
  background: transparent;
}

svg.icon {
  width: 74px;
  height: 74px;
}

.received_content {
  background: white;
  width: 100%;
}

.coin_block {
  font-weight: bold;
  color: #1f1f1f;
}

.achievement_block,
.coin_block,
.total_coin {
  width: 50%;
  font-weight: bold;
}

@media (max-width: 599px) {
  ::v-deep .v-dialog {
    margin: 0;
    max-height: 95%;
  }
}
</style>
